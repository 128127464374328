import CenterContainer from '../Layout/CenterContainer';
import React from 'react';
import OmsTitle from '../CustomComponent/OmsTitle';
import ScrollContainer from '../Layout/ScrollContainer';
import './TermsAndConditionsPage.scss';
import TermsAndConditionsContent from './TermsAndConditionsContent';

const DuplicatePage = () => {
  return <CenterContainer isVisible={true} width={1700} scroll={'scroll'} height={'90vh'}
                          className={'terms-and-conditions'}>
    <OmsTitle level={2} textAlign={'center'} textColor={'text-color-white'}>You are open more than 1 tab or window</OmsTitle>
    
  </CenterContainer>
}

export default DuplicatePage;
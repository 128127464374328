import { CloseOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, Flex, Image, Modal, Space, Spin, Typography, notification } from 'antd'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import CharacterChoosePopup from 'components/SchoolModule/CharacterChoosePopup'
import CharacterChoosePopupCrafting from 'components/SchoolModule/CharacterChoosePopupCrafting'
import { CharacterInfo, loadCharacterInfo, loadCharacterInfoSelector } from 'components/SchoolModule/CharacterSlide'
import { ModulesData, loadAllModulesSelector } from 'components/SchoolModule/SchoolModuleSlice'
import { loadUserGameInfo } from 'components/SchoolModule/gameUserInfoSlide'
import { postJSON } from 'helpers/common'
import { API_CLAIM_CRAFTING_ITEM, API_CRAFTING_ITEM, API_GET_RANDOM_TEMP_NFT, API_MINT_TEMP_NFT } from 'helpers/config'
import { useEffect, useState } from 'react'

interface LabProps {
    isOpen: boolean,
    setIsOpen: Function
}

export interface ItemInfo {
    id: string,
    name: string,
    receiptId: string,
    resourcesRequired: {
        [index: string]: number
    },
    characterRequired: {
        [index: string]: number
    }
    crafting_time: number
}

let remainingTimeIntevalId: any = null;

const Labotory = ({ isOpen, setIsOpen }: LabProps) => {
    type NotificationType = 'success' | 'info' | 'warning' | 'error';

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    const [listItem, setListItem] = useState<any[]>();
    const [choosedMd, setChoosedMd] = useState<any>();
    const [isBuyLoading, setIsBuyLoading] = useState<boolean>(false);
    const [api, contextHolder] = notification.useNotification();
    const [listCharacterAvalable, setListCharacterAvalable] = useState<CharacterInfo[]>([]);
    const userCharacterInfo = useAppSelector(loadCharacterInfoSelector);
    const [choosedCharacter, setChoosedCharacter] = useState<CharacterInfo>();
    const [isMining, setIsMining] = useState<boolean>(false);
    const [miningModule, setMiningModule] = useState<ModulesData>();
    const schoolModule = useAppSelector(loadAllModulesSelector);
    const [isMiningModule, setIsMiningModule] = useState<boolean>(false);
    const [choosedResourceId, setChoosedResourceId] = useState<string>('');
    const [timeCountDown, setTimeCountDown] = useState<number>();

    const [isBrewing, setIsBrewing] = useState<boolean>(false);
    const [isClaiming, setIsClaiming] = useState<boolean>(false);

    const [choosedItem, setChoosedItem] = useState<ItemInfo>();

    notification.config({
        placement: 'topRight',
        duration: 3,
        rtl: true,
    })

    const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
        api[type]({
            message: message,
            description: description
        });
    };

    const item = [
        {
            id: 'healing_potion',
            name: 'Healing Potion',
            receiptId: '661c911d7f490329b20a4b92',
            resourcesRequired: {
                wood: 3,
                coal: 4
            },
            characterRequired: {
                earth: 1
            },
            crafting_time: 30
        }
    ]

    const getGameItem = async () => {
        setIsLoading(true);
        const result = item;
        setListItem(result);
        setIsLoading(false);
    }

    const miningModuleId = 'LAB1'


    useEffect(() => {
        if (isOpen) {
            setIsLoading(true);
            const result = item;
            setListItem(result);
            if (miningModuleId && schoolModule && userCharacterInfo) {
                setChoosedCharacter(undefined);
                const characterInmining = userCharacterInfo?.find(info => info.mining_module === miningModuleId);
                console.log(characterInmining);
                if (characterInmining) {
                    setIsMining(true);
                    setChoosedCharacter(characterInmining);
                    setChoosedItem(item.find(item => item.id === characterInmining.crafting_item));
                } else {
                    setIsMining(false);
                    setChoosedCharacter(undefined);
                }
                const characters = userCharacterInfo?.filter(character => (!character.mining_module));
                if (characters) {
                    setListCharacterAvalable(characters);
                }

                const _miningModule = schoolModule.find(module => module.id === miningModuleId);
                if (characterInmining && _miningModule) {
                    setChoosedResourceId(characterInmining.mining_resource);
                }
                setMiningModule(_miningModule);
                if (_miningModule) {
                    setIsMiningModule(_miningModule.yield_attribute.length > 0 || _miningModule.yield_resources.length > 0);
                }
            }
            setIsLoading(false);
        }
    }, [isOpen, miningModuleId, schoolModule, userCharacterInfo])

    useEffect(() => {
        if (remainingTimeIntevalId) {
            clearInterval(remainingTimeIntevalId);
            remainingTimeIntevalId = null;
        }
        if (isMining && miningModule) {
            if (remainingTimeIntevalId) {
                clearInterval(remainingTimeIntevalId);
                remainingTimeIntevalId = null;
            }
            let _remainingTime = setInterval(() => countDown(miningModuleId), 1000);
            if (_remainingTime)
                remainingTimeIntevalId = _remainingTime;
        }
    }, [miningModule, isMining])

    const onclickChooseMd = (mdId: string) => {
        const _choosedMd = listItem?.find(md => md._id === mdId);
        console.log(_choosedMd);
        if (_choosedMd) {
            setChoosedMd(_choosedMd);
        }
    }

    function handleChooseItem(itemId: string): void {
        const _chooseItem = item.find(item => item.id === itemId);
        setChoosedItem(_chooseItem);
    }

    async function handleBrewItem(gameCharId: string, itemId: string) {
        setIsBrewing(true);
        const data = {
            gameCharId: gameCharId,
            itemId: itemId,
            module_id: miningModule?.id
        }
        const resutl = await postJSON(API_CRAFTING_ITEM, data);
        dispatch(loadCharacterInfo());
        dispatch(loadUserGameInfo());
        setIsBrewing(false);
    }

    async function handleClaimItem(gameCharId: string) {
        setIsClaiming(true);
        const data = {
            gameCharId: gameCharId,
        }
        const resutl = await postJSON(API_CLAIM_CRAFTING_ITEM, data);
        dispatch(loadCharacterInfo());
        setIsMining(false);
        setTimeCountDown(undefined);
        console.log(resutl);
        setIsClaiming(false);
    }

    const countDown = (miningModuleId: string) => {
        const charInfoMining = userCharacterInfo?.find(info => info.mining_module === miningModuleId);
        const module = schoolModule.find(module => module.id === miningModuleId);
        if (charInfoMining && module) {
            const crafting_time = (Date.now() - charInfoMining.crafting_start_time) / 1000
            let remainingTime = Math.floor(item[0].crafting_time - crafting_time);

            if (remainingTime < 0) {
                remainingTime = 0;
            }

            setTimeCountDown(remainingTime);

        }
    }

    const CraftingButton = () => {
        return (
            <>
                {choosedCharacter && choosedItem && !choosedCharacter.crafting_start_time
                    ? <Button loading={isBrewing} type='primary' onClick={() => handleBrewItem(choosedCharacter._id, choosedItem.id)} style={{ margin: '10px' }}>Brew</Button>
                    : isMining && timeCountDown && timeCountDown > 0 ? <Button type='primary' disabled style={{ margin: '10px', color: 'white' }}>Claiming</Button> : choosedCharacter ? <Button type='primary' loading={isClaiming} onClick={() => handleClaimItem(choosedCharacter._id)} style={{ margin: '10px', color: 'black' }}>Claiming</Button> : null}
            </>
        )
    }

    const onClose = () => {
        setIsOpen(false);
        setChoosedCharacter(undefined);
        setChoosedItem(undefined);
        setIsMining(false);
        setTimeCountDown(undefined);

    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorText: 'white'
                },
                components: {
                    Modal: {
                        contentBg: 'rgba(0, 0, 0, 0.8)',
                        colorText: 'white'
                    },
                }
            }}
        >
            <Modal width='auto' open={isOpen} footer={null}
                onCancel={() => onClose()} centered={true} closeIcon={<CloseOutlined style={{ color: 'white' }} />}>
                {contextHolder}
                {isLoading
                    ? <Flex style={{ width: '1000px', height: '600px' }} justify='center' align='center'><Spin /></Flex>
                    : <Flex style={{ width: '1000px', height: '600px' }}>
                        <Flex justify='space-around' wrap='wrap' style={{ width: '100%', margin: '50px' }}>
                            <Flex align='center' wrap='wrap' vertical={true} style={{ width: '15%', borderStyle: 'solid', borderWidth: '1px' }}>
                                <Typography.Title level={4}>Recipe</Typography.Title>
                                {listItem?.map(item => (
                                    <Button onClick={() => handleChooseItem(item.id)} style={{ border: 'none', backgroundColor: 'inherit' }}>{item.name}</Button>
                                ))}
                            </Flex>
                            <Flex align='center' wrap='wrap' vertical={true} style={{ width: '45%', borderStyle: 'solid', borderWidth: '1px' }}>
                                <Typography.Title level={4}>Labotory</Typography.Title>
                                {choosedCharacter
                                    ? <Image
                                        width={200}
                                        src={choosedCharacter?.metadata.image}
                                        preview={false}

                                    />
                                    : <div style={{ border: 'solid', width: '200px', height: '250px', borderWidth: '1px' }}></div>
                                }
                                <Typography.Title level={5}>{choosedItem?.name}</Typography.Title>
                                {choosedItem ? Object.entries(choosedItem.resourcesRequired).map(([key, value]) => (
                                    <Typography key={key}>
                                        <strong>{key}</strong>: {value.toString()}
                                    </Typography>
                                )) : null}
                                {choosedItem ? Object.entries(choosedItem.characterRequired).map(([key, value]) => (
                                    <Typography key={key}>
                                        <strong>{key}</strong>: {value.toString()}
                                    </Typography>
                                )) : null}

                                {choosedItem && !isMining ? <Typography>Duration: {choosedItem.crafting_time}s</Typography> : null}
                                {isMining ? <Typography>Duration: {timeCountDown}s</Typography> : null}
                                <CraftingButton />

                            </Flex>
                            <Flex align='center' wrap='wrap' vertical={true} style={{ width: '15%', borderStyle: 'solid', borderWidth: '1px' }}>
                                <Typography.Title level={4}>Character</Typography.Title>
                                {miningModule && choosedItem ? <CharacterChoosePopupCrafting listAvailableCharacter={listCharacterAvalable} setListAvailableCharacter={setListCharacterAvalable}
                                    setChooseCharacter={setChoosedCharacter} choosedCharacter={choosedCharacter} isMining={isMining} module={miningModule} craftingItem={choosedItem} /> : null}
                            </Flex>
                        </Flex>
                    </Flex>
                }

            </Modal>
        </ConfigProvider>
    )
}

export default Labotory;
import React, { useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Layout } from 'antd';
import GameDemo from 'components/GameDemo/GameDemo';
import Account from 'components/Account/AccountPage';
// import Mint from 'components/Mint';
import MintWithPackages from 'components/MintWithPackages';
import TWCallback from 'components/TWCallback';
import VerifyPage from 'components/VerifyPage';
import AFFReward from 'components/AFFReward/AFFReward';
import ClaimShard from 'components/ClaimShard/ClaimShard';
import ShardWhitelist from 'components/ShardWhitelist/ShardWhitelist';
import DiscordCallback from 'components/DiscordCallback';
import Text from 'antd/lib/typography/Text';
import { Modal } from 'antd';
import './style.css';
import UserForm from './components/UserForm';
import Content from './components/Layout/Content';
import { shallowEqual, useSelector } from 'react-redux';
import {
  getUserInfo,
  loginUser, logout, resetUserFormRequestStatus,
  stepSelector, tokenInfoSelector, tokenRefreshTimeSelector
} from './components/UserForm/UseSlice';
import { useAppDispatch, useAppSelector } from './app/hooks';
import LoadingMask from './components/LoadingMask/LoadingMask';
import Home from './components/Home';
import HomePhaser from './components/HomePhaser/Home_Phaser';
import Profile from 'components/Profile/Profile';
import BurgerMenu from './components/BurgerMenu';
import SharePopover from './components/SharePopover';
import TermsAndConditionsPage from './components/Pages/TermsAndConditionsPage';
import DuplicatePage from './components/Pages/DuplicatePage';
import { updateAppWelcome } from './app/AppSlice';
import NFTCharacter from './components/NFT/NFTCharacter';
import { isMobile } from 'react-device-detect';
import ChangePassword from './components/Account/ChangePassword';
import { loadAppConfig } from './app/AppConfigSlice';
import { ethers } from 'ethers';
import { URLS } from './helpers/chains';
import { ActiveNetworks } from './helpers/config';
import MainProviderContext from './context/MainProvider';
import Title from 'antd/lib/typography/Title';
import SchoolModule from 'components/SchoolModule/SchoolModule';
import Aldoria from 'components/Aldoria/Aldoria';
// import ResourceShow from 'components/ResourceShow';
import { loadUserGameInfo } from 'components/SchoolModule/gameUserInfoSlide';
import ResourceShow from 'components/ResourceShow';
import { loadAllModules } from 'components/SchoolModule/SchoolModuleSlice';
import { loadCharacterInfo } from 'components/SchoolModule/CharacterSlide';
import { NavLink } from 'react-router-dom';
import { buildDate } from 'helpers/common';
import { loadAllResources } from 'components/SchoolModule/ResourceSlide';

function isHome(location: Location) {
  return location.pathname?.toLowerCase()?.match(/\/home\/?/);
}

const mainProvider = new ethers.providers.JsonRpcProvider(
  URLS[ActiveNetworks.polygon][0]
);

export const App = () => {
  const stepLogin = useSelector(stepSelector);
  const tokenRefeshTime = useSelector(tokenRefreshTimeSelector, shallowEqual);
  const tokenInfo = useAppSelector(tokenInfoSelector, shallowEqual)
  const dispatch = useAppDispatch();
  const location = window.location;
  const [intervalRefreshToken, setIntervalRefreshToken] = React.useState<any>(null);

  const [showInstallMetamask, setShowInstallMetamask] = React.useState(false);

  useEffect(() => {
    if (!isHome(location)) {
      dispatch(updateAppWelcome(true));
    }
    dispatch(resetUserFormRequestStatus());
    PubSub.subscribe('showInstallMetamask', (msg: any, data: any) => {
      setShowInstallMetamask(true);
    });
  }, []);

  useEffect(() => {
    if (intervalRefreshToken) {
      clearInterval(intervalRefreshToken);
    }
    setIntervalRefreshToken(setInterval(() => {
      if (!tokenRefeshTime) {
        return;
      }
      if (Date.now() > tokenRefeshTime) {
        console.log('tokenRefeshTime need refresh ' + tokenRefeshTime)
        dispatch(loginUser({ provider: 'Token' }));
      }
    }, 10 * 60000));
    return () => intervalRefreshToken && clearInterval(intervalRefreshToken);
  }, [tokenRefeshTime]);

  useEffect(() => {
    console.log('start checking token');
    console.log('token info: ');
    for (const [key, value] of Object.entries(tokenInfo)) {
      console.log(`${key}: ${value}`);
    }

    if (tokenInfo) {
      if (stepLogin === 'logged-in') {
        console.log('load app config');
        dispatch(loadAppConfig());
        if (tokenInfo.tokenExpiredTime && Date.now() > tokenInfo.tokenExpiredTime) {
          console.log('token expired, logging out');
          dispatch(logout());
        } else if (tokenInfo.tokenRefreshTime && Date.now() > tokenInfo.tokenRefreshTime) {
          console.log('refresh token');
          dispatch(loginUser({ provider: 'Token' }));
        } else if (!tokenInfo.uid && (!stepLogin || stepLogin === 'logged-in')) {
          console.log('invalid token');
          dispatch(logout());
        } else {
          console.log('load game data');
          loadGameUserInfoData();
          loadUserCharacterInfo();
          loadAllModuleData();
          loadAllResourcesInfoData();
          loadUserInfo();
        }
      }
    } else {
      console.log('token not found, logging out ');
      dispatch(logout());
    }

  }, [tokenInfo])

  const loadAllResourcesInfoData = async () => {
    try {
      return await dispatch(loadAllResources());
    } catch (e) {
      console.log(e);
      dispatch(logout());
    }
  }

  const loadGameUserInfoData = async () => {
    try {
      return await dispatch(loadUserGameInfo());
    } catch (e) {
      console.log(e);
      dispatch(logout());
    }
  };

  const loadAllModuleData = async () => {
    try {
      return await dispatch(loadAllModules());
    } catch (e) {
      console.log(e);
      dispatch(logout());
    }
  };

  const loadUserCharacterInfo = async () => {
    try {
      return await dispatch(loadCharacterInfo());
    } catch (e) {
      console.log(e);
      dispatch(logout());
    }
  };

  const loadUserInfo = async () => {
    try {
      return await dispatch(getUserInfo());
    } catch (e) {
      console.log(e);
      dispatch(logout());
    }
  }

  const MenuIcon = () => {
    if (isMobile) {
      return <BurgerMenu />
    } else {
      return <NavLink to={'/home'}><img className={'logo-home'} src="/logo-tower-500.png" alt="One Magic School" /></NavLink>
    }
  }


  return (
    <MainProviderContext.Provider value={mainProvider}>
      <GoogleOAuthProvider clientId={String(process.env.REACT_APP_GOOGLE_CLIENT_ID)}>
        <BrowserRouter>
          <Layout
            style={{
              minHeight: isMobile && window.screen.availHeight || '100vh',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              background: 'black',
              backgroundImage: 'url(\'/oms_bg.jpg\')',
              backgroundPosition: 'center center',
              backgroundAttachment: 'fixed',
              WebkitBackgroundSize: 'cover',
              backgroundSize: 'cover',
            }}
          >
            <Content>
              <HomePhaser />
              <Routes>
                {stepLogin === 'logged-in' && <>
                  <Route path="/home" element={<></>} />
                  <Route path="/nfts" element={<Home />} />
                  <Route path="/shard_mine" element={<AFFReward />} />
                  <Route path="/mint" element={<MintWithPackages />} />
                  <Route path="/mint2" element={<MintWithPackages />} />
                  <Route path="/game_demo" element={<GameDemo />} />
                  <Route path="/account" element={<Account />} />
                  <Route path="/changePassword" element={<ChangePassword />} />
                  <Route path="/claim_shard" element={<ClaimShard />} />
                  <Route path="/shard_whitelist" element={<ShardWhitelist />} />
                  <Route path="/school_module" element={<SchoolModule />} />
                  <Route path="/aldoria" element={<Aldoria />} />
                </>}
                {stepLogin !== 'logged-in' && <>
                  <Route path="/signup" element={<UserForm step={'register'} />} />
                  <Route path="/login" element={<UserForm step={'login'} />} />
                </>}
                <Route path="/nfts/gmd/:id" element={<NFTCharacter />} />
                <Route path="/twitter_callback" element={<TWCallback />} />
                <Route path="/discord_callback" element={<DiscordCallback />} />
                <Route path="/verifyUser" element={<VerifyPage />} />
                <Route path="/resetPassword" element={<UserForm step={'reset-password'} />} />
                <Route path="/termsandconditions" element={<TermsAndConditionsPage />} />
                <Route path="/duplicate-tab" element={<DuplicatePage />} />
                <Route
                  path="*"
                  element={<Navigate to={stepLogin === 'logged-in' && '/home' || '/login'} replace />}
                />
              </Routes>
            </Content>
            <div style={{ position: 'fixed', left: 10, bottom: 10 }}>
              <Text style={{ display: 'block', color: 'white' }}>{buildDate}</Text>
            </div>
            <Modal
              title={'Install Metamask'}
              open={showInstallMetamask}
              onCancel={() => {
                setShowInstallMetamask(false);
              }}
              footer={null}
              closable={true}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  fontFamily: 'Roboto',
                }}
              >
                <Title level={4} style={{ textAlign: 'center' }}>
                  {'Please install Metamask'}
                  <br />{' '}
                  <a target="_blank" href="https://metamask.io/download/" rel="noreferrer">
                    https://metamask.io/download/
                  </a>
                </Title>
              </div>
            </Modal>

            {/* <SharePopover /> */}
            {stepLogin === 'logged-in' && <MenuIcon />}
            <Profile />
            {stepLogin === 'logged-in' && <ResourceShow />}
          </Layout>
          <LoadingMask />
        </BrowserRouter>
      </GoogleOAuthProvider>
    </MainProviderContext.Provider>
  );
};

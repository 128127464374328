import OmsModal from 'components/CustomComponent/OmsModal';
import GameQuestUI from './GameQuest';
import { CloseOutlined } from '@ant-design/icons';

interface GameQuestModalProps {
    isOpen: boolean,
    setIsOpen: Function
}

const GameQuestModal: React.FC<GameQuestModalProps> = ({ isOpen, setIsOpen }) => {

    return (
        <>
            <OmsModal width={'600px'} onCancel={() => setIsOpen(false)} closeIcon={<CloseOutlined style={{ color: 'white' }} />} modalName={'home-phase-modal-loading'} className={'coming-soon-modal'} open={isOpen} footer={null}
                centered={true}>
                <GameQuestUI />
            </OmsModal>
        </>
    )
}

export default GameQuestModal;
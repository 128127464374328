import { Popover, Space } from 'antd';
import './characterPopup.scss';
import { CharacterInfo } from './CharacterSlide';
import React from 'react';
import { ModulesData } from './SchoolModuleSlice';
import { ItemInfo } from 'components/Aldoria/Modals/Laboratory';


interface CharacterChoosePopupProp {
  listAvailableCharacter: CharacterInfo[];
  setListAvailableCharacter: Function;
  setChooseCharacter: any,
  choosedCharacter: any,
  isMining: boolean,
  module: ModulesData,
  craftingItem?: ItemInfo
}

export const CharacterChoosePopup = ({ module, setChooseCharacter, choosedCharacter, listAvailableCharacter, setListAvailableCharacter, isMining, craftingItem }: CharacterChoosePopupProp) => {

  const handleCharacterChoose = (char: CharacterInfo) => {
    if (!isMining) {
      if (char.block === 1 && !checkCharacterStatForNotLearning(module, char)) {
        if (choosedCharacter) {
          setListAvailableCharacter([...listAvailableCharacter.filter(character => char._id !== character._id), choosedCharacter]);
          setChooseCharacter(char);
        } else {
          setListAvailableCharacter([...listAvailableCharacter.filter(character => char._id !== character._id)]);
          setChooseCharacter(char);
        }
      }
    }
  }

  const checkCharacterStatForNotLearning = (module: ModulesData, character: CharacterInfo): boolean => {
    if (module.required_stats != null) {
      for (const required_stat of module.required_stats) {
        if (character.stats[required_stat.id] < required_stat.min || character.stats[required_stat.id] > required_stat.max) {
          return true;
        }
      }
    }
    return false;
  }

  const CharacterImg = ({ character }: { character: CharacterInfo }) => {
    const nftThumbnail = character.metadata?.image.replace('png', 'thumb.jpg');
    let classIsLock = character.block !== 1 ? 'isNotLock' : '';
    let classIsNFT = character.nft_id > 0 ? 'isNFT' : '';
    let classCanLearn = checkCharacterStatForNotLearning(module, character) ? 'canNotLearn' : '';
    return (
      <div key={character._id} id={character._id} className={'character-img'}>
        <div className={`${classIsLock} ${classIsNFT} ${classCanLearn}`}>
          <Popover placement="leftTop" content={CharacterInfoContent(character.stats, character.metadata.name, character.block)}>
            <img src={nftThumbnail} id={character._id}
              alt="Character" onClick={() => handleCharacterChoose(character)} />
          </Popover>
        </div>
      </div>
    )
  }

  const ListAvailableCharacter = () => (
    <>
      {listAvailableCharacter?.map(character => (
        <CharacterImg character={character} />
      ))
      }
    </>
  )

  const CharacterInfoContent = (stats: any, name: string, isLock: number) => (
    <div className={'character-info-container'}>
      <p className={'character-name'}>{name}</p>
      {Object.keys(stats).map(key =>
        stats[key] > 0
          ? <p key={key}>{`${key}: ${stats[key]}`}</p>
          : null
      )}
      {isLock === 0 ? <p className={'red-highlight'}>Please lock character to mining resource</p> : null}

    </div>
  )

  return (
    <div className={'character-choose-modal'}>
      <div className={'body-wrapper'}>
        <ListAvailableCharacter />
      </div>
    </div>
  )
}

export default CharacterChoosePopup;
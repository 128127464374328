import { Button, ConfigProvider, Flex, Space, Tabs, TabsProps, Typography, notification } from 'antd';
import { Spin } from 'antd/lib';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { loadUserGameInfo, loadUserGameInfoSelector } from 'components/SchoolModule/gameUserInfoSlide';
import { getUserInfo } from 'components/UserForm/UseSlice';
import './GameQuest.scss';
import { postJSON } from 'helpers/common';
import { API_COMPLETE_QUEST, API_GET_AVALABLE_QUEST, API_GET_COMPLETED_QUEST } from 'helpers/config';
import React, { useEffect, useState } from 'react';

interface Quest {
  id: number;
  name: string;
  questType: string;
  description: string;
  requiredResource?: {
    [index: string]: number
  };
  mapRequiredLevel?: number;
  prize: {
    [index: string]: number
  }
}

interface QuestProp {
  quest: Quest;
  completeQuest: Function;
  completeLoading: {
    loading: number
  }
}

const { Text, Title } = Typography;



const Quest: React.FC<QuestProp> = ({ quest, completeQuest, completeLoading }: QuestProp) => {

  const gameUserInfo = useAppSelector(loadUserGameInfoSelector);
  const [canQuestComplete, setCanQuestComplete] = useState(false);

  const checkQuestCanComplete = () => {
    if (quest.questType === 'Resource Collect') {
      if (quest.requiredResource) {
        Object.entries(quest.requiredResource).forEach(([resourceId, requiredAmount]) => {
          const userResource = gameUserInfo.resources[resourceId];
          if (userResource >= requiredAmount) {
            setCanQuestComplete(true);
          }
        })
      }
    } else if (quest.questType === 'Map Level') {
      if (quest.mapRequiredLevel) {
        if (gameUserInfo.map_level >= quest.mapRequiredLevel) {
          setCanQuestComplete(true);
        }
      }
    }
  }

  useEffect(() => {
    checkQuestCanComplete();
  }, [gameUserInfo])



  return (
    <Flex align='center' justify='space-between'>
      <Flex gap='middle' vertical={true}>
        <Space><Title level={4}>{quest.name}</Title></Space>
        <Space><Text style={{ color: 'green' }}>{quest.description}</Text></Space>
        <Flex gap='middle'>
          {quest.requiredResource ? Object.entries(quest.requiredResource).map(([key, value]) => (
            <Space key={key}><Text style={{ color: 'green' }}>{key}: {value}</Text></Space>
          )) : null}
        </Flex>
        <Flex gap='middle'>
          {quest.mapRequiredLevel ? <Space><Text style={{ color: 'green' }}>Map Level requiere: {quest.mapRequiredLevel}</Text></Space>
            : null}
        </Flex>
        <Flex gap='middle'>
          <Text strong>Prize: </Text> {Object.entries(quest.prize).map(([key, value]) => (
            <Space key={key}><Text>{key}: {value}</Text></Space>
          ))}
        </Flex>
      </Flex>
      <Flex align='center'>
        <Button disabled={!canQuestComplete} size='large' loading={completeLoading.loading === quest.id} onClick={() => completeQuest(quest.id)} type='primary'>Complete</Button>
      </Flex>
    </Flex>
  );
};

interface QuestListProp {
  quests: Quest[] | undefined;
  completeQuest: Function;
  completeLoading: {
    loading: number
  }
}


const QuestList: React.FC<QuestListProp> = ({ quests, completeQuest, completeLoading }: QuestListProp) => {
  return (
    <Flex className='quest_list_container' gap="large" vertical={true} style={{ overflow: 'scroll', maxHeight: '400px' }}>
      {quests
        ? quests.map(quest => (
          <Quest key={quest.id} quest={quest} completeQuest={completeQuest} completeLoading={completeLoading} />
        ))
        : null}
    </Flex>
  );
};

const QuestHistory: React.FC = () => {
  const [quests, setQuests] = useState<Quest[]>();
  const [loading, setLoading] = useState<Boolean>(true);
  const gameUserInfo = useAppSelector(loadUserGameInfoSelector);


  useEffect(() => {
    getCompletedQuest();
  }, [gameUserInfo])

  const getCompletedQuest = async () => {
    setLoading(true);
    const result = await postJSON(API_GET_COMPLETED_QUEST);
    const quest = result.data as Quest[];
    setQuests(quest);
    setLoading(false);
  }



  return (
    <Flex className='quest_list_container' gap="large" vertical={true} style={{ overflow: 'scroll', maxHeight: '400px' }}>
      {
        loading
          ? <Flex justify='center' align='center'><Spin /></Flex>
          : quests
            ? quests.map(quest => (
              <Flex gap='middle' vertical={true}>
                <Space><Title level={4}>{quest.name}</Title></Space>
                <Space><Text style={{ color: 'green' }}>{quest.description}</Text></Space>
                <Flex gap='middle'>
                  <Text strong>Prize: </Text> {Object.entries(quest.prize).map(([key, value]) => (
                    <Space key={key}><Text>{key}: {value}</Text></Space>
                  ))}
                </Flex>
              </Flex>
            ))
            : null}
    </Flex>
  )
}

const GameQuestUI: React.FC = () => {
  type NotificationType = 'success' | 'info' | 'warning' | 'error';

  const [quests, setQuests] = useState<Quest[]>();
  const [loading, setLoading] = useState<Boolean>(true);
  const [completeQuestLoading, setCompleteQuestLoading] = useState<any>({ loading: null });
  const dispatch = useAppDispatch();
  const [api, contextHolder] = notification.useNotification();
  notification.config({
    placement: 'topRight',
    duration: 3,
    rtl: true,
  })

  const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
    api[type]({
      message: message,
      description: description
    });
  };

  const getAvailableQuest = async () => {
    setLoading(true);
    const result = await postJSON(API_GET_AVALABLE_QUEST);
    const quest = result.data as Quest[];
    setQuests(quest);
    setLoading(false);
  }


  useEffect(() => {
    getAvailableQuest();
  }, [])

  const completeQuest = async (questId: number) => {
    setCompleteQuestLoading({ loading: questId });
    const data = {
      questId: questId
    };
    const result = await postJSON(API_COMPLETE_QUEST, data);
    console.log(result);
    if (result.data === 'Quest Completed') {
      setQuests((prevQuest) => prevQuest?.filter(quest => quest.id != questId));
    } else {
      openNotificationWithIcon('error', 'Complete Failed', result.data);
    }
    await dispatch(getUserInfo());
    await dispatch(loadUserGameInfo());
    setCompleteQuestLoading({ loading: null });
  };

  const items: TabsProps['items'] = [
    { key: '1', label: 'Available Quest', children: <QuestList quests={quests} completeQuest={completeQuest} completeLoading={completeQuestLoading} /> },
    { key: '2', label: 'Completed Quest', children: <QuestHistory /> },
  ];

  return (
    <Flex gap='large' vertical={true} style={{ width: '100%' }}>
      {contextHolder}
      <Space align='center' ><Title level={2}>Game Quests</Title></Space>
      {loading
        ? <Flex justify='center' align='center'><Spin /></Flex>
        : <ConfigProvider
          theme={{
            components: {
              Tabs: {
                itemColor: '#076CB2',
                colorTextDisabled:'rgba(0, 0, 0, 0.75)'
              },
            },
          }}
        >
          <Tabs defaultActiveKey="1" items={items} />
        </ConfigProvider>

      }
    </Flex>
  );
};

export default GameQuestUI;

import { CloseOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, Flex, Image, Modal, Space, Spin, Typography, notification } from 'antd'
import { useAppDispatch } from 'app/hooks'
import { getUserInfo } from 'components/UserForm/UseSlice'
import { postJSON } from 'helpers/common'
import { API_GET_RANDOM_TEMP_NFT, API_MINT_TEMP_NFT } from 'helpers/config'
import { useEffect, useState } from 'react'
import './tavern.scss';
import { loadCharacterInfo } from './CharacterSlide';
import Pubsub from 'pubsub-js';


interface TavernProps {
  isOpen: boolean,
  setIsOpen: Function
}





const Tavern = ({ isOpen, setIsOpen }: TavernProps) => {
  type NotificationType = 'success' | 'info' | 'warning' | 'error';

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [listMd, setListMd] = useState<any[]>();
  const [choosedMd, setChoosedMd] = useState<any>();
  const [isBuyLoading, setIsBuyLoading] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useAppDispatch();

  const PUBSUB_ALDORIA_PHASER_IN = 'aldoria:phaser:in';

  notification.config({
    placement: 'topRight',
    duration: 3,
    rtl: true,
  })

  const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
    api[type]({
      message: <div style={{color: 'black'}}>{message}</div>,
      description: <div style={{color: 'black'}}>{description}</div>
    });
  };

  const getRandomNFT = async () => {
    setIsLoading(true);
    const result = await postJSON(API_GET_RANDOM_TEMP_NFT);
    setListMd(result.data);
    setIsLoading(false);

  }

  const buyTempMd = async (mdId: string) => {
    setIsBuyLoading(true);
    const data = {
      md_id: mdId
    }
    const result = await postJSON(API_MINT_TEMP_NFT, data);
    if (result.data === 'SUCCESS') {
      await dispatch(getUserInfo());
      await dispatch(loadCharacterInfo());
      setListMd((prevMd) => prevMd?.filter(md => md._id != mdId));
      openNotificationWithIcon('success', 'Buy successfully', 'Your magic disciple is ready');
      setChoosedMd(null);
    } else {
      openNotificationWithIcon('error', 'Complete Failed', result.error);
    }
    setIsBuyLoading(false);
  }

  useEffect(() => {
    if (isOpen) {
      getRandomNFT();
    }
  }, [isOpen])

  const onlickChooseMd = (mdId: string) => {
    const _choosedMd = listMd?.find(md => md._id === mdId);
    console.log(_choosedMd);
    if (_choosedMd) {
      setChoosedMd(_choosedMd);
    }
  }

  const onClose = () => {
    setIsOpen(false);
    Pubsub.publish(PUBSUB_ALDORIA_PHASER_IN, true);
  }

  return (
    <div>
      {contextHolder}
    <ConfigProvider
      theme={{
        token: {
          colorText: 'white'
        },
        components: {
          Modal: {
            contentBg: 'rgba(0, 0, 0, 0.8)',
            colorText: 'white'
          },
        }
      }}
    >
      <Modal open={isOpen} footer={null}
        onCancel={() => onClose()} centered={true} destroyOnClose={true} closeIcon={<CloseOutlined style={{ color: 'white' }} />}>
        {contextHolder}
        {isLoading
          ? <Flex justify='center' align='center'><Spin /></Flex>
          : <Flex wrap='wrap' vertical={true}>
            <Flex justify='center'><Typography.Title level={4}>Tavern</Typography.Title></Flex>
            <Flex gap={10} justify='center' wrap='wrap'>
              {listMd
                ? listMd.map(md => (
                  <img className={choosedMd && choosedMd._id === md._id ? 'isClick' : ''} style={{ cursor: 'pointer', borderRadius: '10px'}} key={md._id} width={100} src={md.thumpImage} onClick={() => onlickChooseMd(md._id)}></img>
                ))
                : null
              }
            </Flex>
            {choosedMd
              ? <Flex vertical={true}>
                <Flex wrap='wrap' vertical={true}>
                  <Flex justify='center'><Typography.Title level={5}>Magic Disciple</Typography.Title></Flex>
                  <Flex vertical={true}>
                    <Flex justify='center' wrap='wrap'><Typography.Title level={5}>{choosedMd.name}</Typography.Title></Flex>
                    <Flex justify='space-between'>
                      <Flex style={{ width: '30%' }} vertical={true}>
                        <Typography.Title level={5}>Stats</Typography.Title >
                        <Flex justify='space-between'>
                          <Flex vertical={true}>
                            <Typography>Earth:</Typography>
                            <Typography>Air:</Typography>
                            <Typography>Fire:</Typography>
                            <Typography>Water:</Typography>
                            <Typography>Strengh:</Typography>
                            <Typography>Health:</Typography>
                            <Typography>Speed:</Typography>
                          </Flex>
                          <Flex vertical={true}>
                            <Typography>0</Typography>
                            <Typography>0</Typography>
                            <Typography>0</Typography>
                            <Typography>0</Typography>
                            <Typography>0</Typography>
                            <Typography>20</Typography>
                            <Typography>1</Typography>
                          </Flex>
                        </Flex>
                      </Flex>
                      <Flex vertical={true}>
                        <Typography.Title level={5}>Abilities</Typography.Title>
                        <Flex gap={10}>
                          <img width={50} height={50} src='/skillIcons/knives.png' />
                          <img width={50} height={50} src='/skillIcons/shield-back.png' />
                          <img width={50} height={50} src='/skillIcons/shield.png' />
                          <img width={50} height={50} src='/skillIcons/sword.png' />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex justify='flex-end' gap={10}>
                  <Flex align='center'>
                    <Typography>cost: {choosedMd.price} shards</Typography>
                  </Flex>
                  <Button onClick={() => buyTempMd(choosedMd._id)} loading={isBuyLoading} size='large' type='primary'>Buy</Button>
                </Flex>
              </Flex>
              : null
            }

          </Flex>
        }

      </Modal>
    </ConfigProvider>
    </div>
  )
}

export default Tavern;
// Create hook to use OMS Web3

import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { activeWithConnectorId, deactivate, URLS } from '../helpers/chains';
import WalletConnectProvider from '@walletconnect/ethereum-provider';
import { MetamaskProvider } from '@ethersproject/experimental/lib/metamask-provider';
import { MetaMask } from '@web3-react/metamask';
import { WalletConnect } from '@web3-react/walletconnect';
import type { Connector } from '@web3-react/types';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { userInfoSelector } from '../components/UserForm/UseSlice';
import Pubsub from 'pubsub-js';
import { BigNumber, ethers } from 'ethers';
import { abiList, ActiveNetworks, NFTContractList } from '../helpers/config';
import { updateWeb3Loading } from '../app/AppSlice';
import  {walletConnect as WalletConnectConnector}  from 'connectors/walletConnectV2';
import { exit } from 'process';

interface OmsWeb3 {
  account?: string;
  provider?: Web3Provider;
  isActive?: boolean;
  setStep: (step: 'hold' | 'retry' | 'processing' | 'success' | 'error') => void;
  providerName?: 'injected' | 'walletconnect',
  chainId?: number,
  flatSig?: string,
  deactivate?: () => void,
  connector?: Connector,
  connect: (walletType: 'injected' | 'walletconnect') => string,
  mint: (params: any) => string,
  transfer: (params: any) => string,
  unlock: (params: any) => string,
  lock: (params: any) => string,
  checkLock: (nft_token: number) => Promise<boolean>,
  ownerOf: (tokenId: number) => Promise<string>,
  walletOfOwner: () => Promise<number[]>,
  checkNFTStatus: (nft_tokens: number[]) => Promise<boolean[]>,
}


export const useOmsWeb3 = (): OmsWeb3 => {
  const {account, provider, isActive, chainId, connector} = useWeb3React();
  const [walletType, setWalletType] = useState<'injected' | 'walletconnect'>();
  const [flatSig, setFlatSig] = useState<string>();
  const [method, setMethod] = useState<'connect' | 'lock' | 'unlock' | 'transfer' | 'mint'>();
  const userInfo = useAppSelector(userInfoSelector);

  const [topic, setTopic] = useState<string>('default');
  const [step, setStep] = useState<'hold' | 'retry' | 'processing' | 'success' | 'error'>('hold');
  const [mintParams, setMintParams] = useState<any>();
  const [transferParams, setTransferParams] = useState<any>();
  const [unlockParams, setUnlockParams] = useState<any>();
  const [lockParams, setLockParams] = useState<any>();

  const dispatch = useAppDispatch();
  const connect = (walletType: 'injected' | 'walletconnect'): string => {
    const topic = 'connect-' + new Date().getTime();
    setWalletType(walletType);
    setMethod('connect');
    setStep('processing');
    console.log(`setting connect topic: ${topic}`);
    setTopic(topic);
    return topic;
  }
  const mint = (params: any): string => {
    const topic = 'mint-' + new Date().getTime();
    const walletType = userInfo.walletType;
    if (walletType !== 'injected' && walletType !== 'walletconnect') {
      setStep('error');
      onErrorTopic(topic, new Error('No wallet type'));
      return topic;
    }
    setWalletType(walletType);
    setMintParams(params);
    setMethod('mint');
    setStep('processing');
    setTopic(topic);
    return topic;
  }

  const transfer = (params: any): string => {
    const topic = 'transfer-' + new Date().getTime();
    const walletType = userInfo.walletType;
    if (walletType !== 'injected' && walletType !== 'walletconnect') {
      setStep('error');
      onErrorTopic(topic, new Error('No wallet type'));
      return topic;
    }
    setWalletType(walletType);
    setTransferParams(params);
    setMethod('transfer');
    setStep('processing');
    console.log(`setting transfer topic: ${topic}`);
    setTopic(topic);
    return topic;
  }

  const unlock = (params: any): string => {
    const currenttopic = 'unlock-' + new Date().getTime();
    const walletType = userInfo.walletType;
    if (walletType !== 'injected' && walletType !== 'walletconnect') {
      setStep('error');
      onErrorTopic(topic, new Error('No wallet type'));
      return topic;
    }
    setWalletType(walletType);
    setUnlockParams(params);
    setMethod('unlock');
    setStep('processing');
    console.log(`setting unlock topic: ${currenttopic}`);
    setTopic(currenttopic);
    return currenttopic;
  }

  const checkLock = async (nft_token: number) => {
    const main_provider = new ethers.providers.JsonRpcProvider(
      URLS[ActiveNetworks.polygon][0]
    );

    const nftContract = new ethers.Contract(
      // @ts-ignore
      NFTContractList[ActiveNetworks.polygon],
      abiList.abiNFT,
      main_provider
    );

    const isLock = await nftContract.unlockedList(nft_token);
    return isLock;
  }

  const checkNFTStatus = async (nft_tokens: number[]): Promise<boolean[]> => {
    const main_provider = new ethers.providers.JsonRpcProvider(
      URLS[ActiveNetworks.polygon][0]
    );

    const nftContract = new ethers.Contract(
      // @ts-ignore
      NFTContractList[ActiveNetworks.polygon],
      abiList.abiNFT,
      main_provider
    );
    return await Promise.all(
      nft_tokens.map(nft_token => nftContract.unlockedList(nft_token)));
  }

  const ownerOf = async (tokenId: number): Promise<string> => {
    const main_provider = new ethers.providers.JsonRpcProvider(
      URLS[ActiveNetworks.polygon][0]
    );

    const nftContract = new ethers.Contract(
      // @ts-ignore
      NFTContractList[ActiveNetworks.polygon],
      abiList.abiNFT,
      main_provider
    );

    return await nftContract.ownerOf(tokenId);
  }

  const walletOfOwner = async (): Promise<number[]> => {
    console.log('walletOfOwner');
    const main_provider = new ethers.providers.JsonRpcProvider(URLS[ActiveNetworks.polygon][0]);
    const nftContract = new ethers.Contract(
      // @ts-ignore
      NFTContractList[ActiveNetworks.polygon],
      abiList.abiNFT,
      main_provider
    );

    const ids = (await nftContract.walletOfOwner(userInfo.wallet)).map((id: BigNumber) => id.toNumber());
    console.log('walletOfOwner', ids);
    return ids;
  }

  const lock = (params: any): string => {
    const currenttopic = 'lock-' + new Date().getTime();
    const walletType = userInfo.walletType;
    if (walletType !== 'injected' && walletType !== 'walletconnect') {
      setStep('error');
      onErrorTopic(currenttopic, new Error('No wallet type'));
      return currenttopic;
    }
    setWalletType(walletType);
    setLockParams(params);
    setMethod('lock');
    setStep('processing');
    console.log(`setting lock topic: ${currenttopic}`);
    setTopic(currenttopic);
    return currenttopic;
  }

  const onSuccessTopic = (topic: string, data: any) => {
    console.log(`publishing onSuccessTopic, topic: ${topic}`)
    Pubsub.publish(topic, {data});
  }

  const onErrorTopic = (topic: string, error: any) => {
    console.log('publishing onErrorTopic')
    Pubsub.publish(topic, {error});
  }

  useEffect(() => {
    if (account && userInfo?.wallet && userInfo?.wallet?.toLowerCase() !== account?.toLowerCase()) {
      console.log('wallet active but not correct, deactivating');
      deactivate();
    }
  }, [account]);
  const getProviderName = (provider?: Web3Provider) => {
    if (!provider) {
      return undefined;
    }

    return provider.provider.isMetaMask ? 'injected' : 'walletconnect';
  }
  const getConnector = (provider?: Web3Provider) => {
    if (!provider) {
      return undefined;
    }
    if (provider.provider instanceof MetamaskProvider) {
      return MetaMask;
    }
    if (provider.provider instanceof WalletConnectProvider) {
      return WalletConnect;
    }
    return undefined;
  }
  useEffect(() => {
    if (step === 'processing' || step === 'retry') {
      switch (method) {
        case 'connect':
          onProcessing(onConnect);
          break;
        case 'mint':
          onProcessing(onMint);
          break;
        case 'transfer':
          onProcessing(onTransfer);
          break;
        case 'unlock':
          onProcessing(onUnlock);
          break;
        case 'lock':
          onProcessing(onLock);
          break;
      }
    }
  }, [step]);

  function onConnect() {
    console.log('account active -> sign message (onConnect)');
    let p: any = provider;
    const signer = p.getSigner();
    console.log(signer, account)
    dispatch(updateWeb3Loading(true));
    signer.signMessage(
      'Login with Wallet'
    ).then((flatSig: string) => {
      setFlatSig(flatSig);
      setStep('success');
      onSuccessTopic(topic, flatSig);
    }).catch((e: Error) => {
      console.log('error',e);
      setStep('error');
      onErrorTopic(topic, e);
    }).finally(() => {
      dispatch(updateWeb3Loading(false));
    });
    return;
  }

  function onMint() {
    const {rel, mintAmount} = mintParams;
    const main_provider = new ethers.providers.JsonRpcProvider(
      URLS[ActiveNetworks.polygon][0]
    );

    console.log('account active -> sign message (onMint)');
    let p: any = provider;
    const signer = p.getSigner();
    console.log(signer, account)

    const nftContract = new ethers.Contract(
      // @ts-ignore
      NFTContractList[ActiveNetworks.polygon],
      abiList.abiNFT,
      main_provider
    );

    let options = {
      value: ethers.utils.parseEther(rel.value.toFixed(2))
    };

    // console.log((mintAmount*data.price).toString(),options);
    // return;

    let sig = ethers.utils.splitSignature(rel.signature);
    //console.log(rel);
    dispatch(updateWeb3Loading(true));
    nftContract.connect(signer).mint(rel.amount, ethers.constants.AddressZero, 0, rel.signId, rel.expire, sig.v, sig.r, sig.s, options).then((tx: any) => {
      tx.wait().then((tx: any) => {
        onSuccessTopic(topic, tx);
        setStep('success');
      }).finally(() => {
                dispatch(updateWeb3Loading(false));
      });
    }).catch((e: Error) => {
      console.log(e);
      setStep('error');
      onErrorTopic(topic, e);
      dispatch(updateWeb3Loading(false));
    });
    //setLoading(true);
  }

  function onTransfer() {
    const main_provider = new ethers.providers.JsonRpcProvider(
      URLS[ActiveNetworks.polygon][0]
    );

    if (!provider) {
      setStep('error');
      onErrorTopic(topic, new Error('No provider'));
      return;
    }
    const {token_id, receiver} = transferParams;
    const signer = provider.getSigner();
    let network: string = ActiveNetworks.polygon.toString();
    let contractAddress: any = NFTContractList[network as keyof typeof NFTContractList];
    const nftContract = new ethers.Contract(
      contractAddress,
      abiList.abiNFT,
      main_provider
    );
    dispatch(updateWeb3Loading(true));
    nftContract
      .connect(signer)
      .transferFrom(account, receiver, token_id).then((tx: any) => {
      tx.wait().then((tx: any) => {
        setStep('success');
        onSuccessTopic(topic, tx);
      }).catch((e: Error) => {
        setStep('error');
        onErrorTopic(topic, e);
      }).finally(() => {
        dispatch(updateWeb3Loading(false));
      });
    }).catch((e: Error) => {
      setStep('error');
      onErrorTopic(topic, e);
    }).finally(() => {
      dispatch(updateWeb3Loading(false));
    });
  }

  function onUnlock() {
    if (!provider) {
      setStep('error');
      onErrorTopic(topic, new Error('No provider'));
      return;
    }
    console.log(`onUnlock is running, topic: ${topic}`);

    const main_provider = new ethers.providers.JsonRpcProvider(
      URLS[ActiveNetworks.polygon][0]
    );

    const {nft, rel} = unlockParams;
    const signer = provider.getSigner();
    let network: string = ActiveNetworks.polygon.toString();
    let contractAddress: any = NFTContractList[network as keyof typeof NFTContractList];
    const nftContract = new ethers.Contract(
      contractAddress,
      abiList.abiNFT,
      main_provider
    );
    let sig = ethers.utils.splitSignature(rel.signature);
    // console.log('dispatching web3 loading to true');
    dispatch(updateWeb3Loading(true));
    nftContract.connect(signer).UnlockNFT(nft.token_id, rel.signId, sig.v, sig.r, sig.s).then((tx: any) => {
      tx.wait().then((tx: any) => {
        // console.log(`success 1, topic: ${topic}`);
        setStep('success');
        onSuccessTopic(topic, tx);
      }).catch((e: Error) => {
        // console.log(`error 1, topic: ${topic}`);
        setStep('error');
        onErrorTopic(topic, e);
      }).finally(() => {
        // console.log('final 1, dispatching web3 loading to false');
        dispatch(updateWeb3Loading(false));
      });
    }).catch((e: Error) => {
      // console.log(`error 2, topic: ${topic}`);
      setStep('error');
      onErrorTopic(topic, e);
    }).finally(() => {
      // console.log('final 2, dispatching web3 loading to false');
      dispatch(updateWeb3Loading(false));
    });
  }

  function onLock() {
    if (!provider) {
      setStep('error');
      onErrorTopic(topic, new Error('No provider'));
      return;
    }

    const main_provider = new ethers.providers.JsonRpcProvider(
      URLS[ActiveNetworks.polygon][0]
    );

    const {nft, rel} = lockParams;
    const signer = provider.getSigner();
    let network: string = ActiveNetworks.polygon.toString();
    let contractAddress: any = NFTContractList[network as keyof typeof NFTContractList];
    const nftContract = new ethers.Contract(
      contractAddress,
      abiList.abiNFT,
      main_provider
    );
    let sig = ethers.utils.splitSignature(rel.signature);
    //console.log(rel);
    dispatch(updateWeb3Loading(true));

    nftContract.connect(signer).LockNFT(nft.token_id, rel.signId, sig.v, sig.r, sig.s).then((tx: any) => {
      tx.wait().then((tx: any) => {
        setStep('success');
        onSuccessTopic(topic, tx);
      }).catch((e: Error) => {
        setStep('error');
        onErrorTopic(topic, e);
      }).finally(() => {
        dispatch(updateWeb3Loading(false));
      });
    }).catch((e: Error) => {
      setStep('error');
      onErrorTopic(topic, e);
    }).finally(() => {
      dispatch(updateWeb3Loading(false));
    });
  }

  function onProcessing(action: () => void) {
    console.log('onProcessing', {
      step: step,
      walletType,
      account,
      provider,
      isActive,
      flatSig,
      providerName: getProviderName(provider)
    });
    console.log(`step: ${step}, topic: ${topic}`);
    if (step != 'retry' && step != 'processing') {
      console.log(`step: ${step}, not processing nor retrying, ending`)
      return;
    }
    if (step === 'retry') {
      setStep('processing');
      return;
    }

    if (step === 'processing') {
      if (walletType === 'injected' && !window?.ethereum) {
        setStep('error');
        Pubsub.publish('showInstallMetamask', {});
        onErrorTopic(topic, new Error('Wallet injected not found'));
        return;
      }
      if (!walletType) {
        setStep('error');
        onErrorTopic(topic, new Error('No wallet type'));
        return;
      }

      // if wallet active or not the same type, deactivate it
      if (provider && getProviderName(provider) !== walletType) {
        console.log('provider not same type -> deactive it');
        deactivate().then(() => {
          setStep('retry');
        });
      }

      // if wallet is not active or not the same type, activate it
      if (!provider || getProviderName(provider) !== walletType) {
        console.log('provider not active -> active it');
        console.log('activeWithConnectorId', walletType, 0)
        WalletConnectConnector?.deactivate?.();
        activeWithConnectorId(walletType, ActiveNetworks.polygon).then(() => {
          setStep('retry');
        }).catch(e => {
          setStep('error');
          onErrorTopic(topic, e);
        })
        return;
      }
      if (topic == 'default') {
        console.log('default topic, retrying');
        setStep('retry');
      return;
      }

      if (isActive && account && getProviderName(provider) === walletType) {
        console.log('action time')
        action();
        return;
      }
      if (provider && getProviderName(provider) !== walletType) {
        deactivate().then(() => {
          console.log('Provider not defined or different than walletType, Retrying')
          setStep('retry');
        });
        return;
      }
      
    }
  }

  return {
    account,
    provider,
    isActive,
    deactivate: deactivate,
    setStep: setStep,
    providerName: getProviderName(provider),
    chainId,
    flatSig,
    connector,
    connect, mint, transfer, unlock, lock, checkLock, ownerOf, walletOfOwner, checkNFTStatus
  }
}

import {ETHLogo, PolygonLogo} from './Logos';

export const networkConfigs = {
  1: {
    chainId: 1,
    chainName: 'ETH Mainnet',
    currencySymbol: 'ETH',
    blockExplorerUrl: 'https://etherscan.io/',
    wrapped: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    icon: <ETHLogo />,
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  },
  5: {
    chainId: 5,
    chainName: 'Goerli',
    currencySymbol: 'ETH',
    blockExplorerUrl: 'https://goerli.etherscan.io/',
    icon: <ETHLogo />,
    nativeCurrency: { name: 'Görli Ether', symbol: 'görETH', decimals: 18 },
  },
  137: {
    chainId: 137,
    chainName: 'Polygon Mainnet',
    currencyName: 'MATIC',
    currencySymbol: 'MATIC',
    rpcUrl: 'https://rpc-mainnet.maticvigil.com/',
    blockExplorerUrl: 'https://explorer-mainnet.maticvigil.com/',
    wrapped: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    icon: <PolygonLogo />,
    nativeCurrency: { name: 'Polygon Matic', symbol: 'MATIC', decimals: 18 },
  },
  80002: {
    chainId: 80002,
    chainName: 'Amoy',
    currencyName: 'MATIC',
    currencySymbol: 'MATIC',
    rpcUrl: 'https://rpc-amoy.polygon.technology/',
    blockExplorerUrl: 'https://www.oklink.com/amoy',
    icon: <PolygonLogo />,
    nativeCurrency: {
      name: 'Polygon Amoy Matic',
      symbol: 'mMATIC',
      decimals: 18,
    },
  },
};

export const getNativeByChain = (chain) =>
  networkConfigs[chain]?.currencySymbol || 'NATIVE';

export const getChainById = (chain) => networkConfigs[chain] || null;

export const getExplorer = (chain) => networkConfigs[chain]?.blockExplorerUrl;

export const getWrappedNative = (chain) =>
  networkConfigs[chain]?.wrapped || null;
export async function switchNetwork(library, chainId, authenticate = null) {
  if (!library?.provider?.request) {
    return;
  }

  // if (library?.provider?.wc) {
  //   if (library?.provider?.wc?._peerMeta?.name != "MetaMask") {
  //     console.log(
  //       library,
  //       library?.provider?.wc?._peerMeta?.name,
  //       authenticate,
  //       chainId,
  //     );
  //     const info = networkConfigs[chainId];
  //     if (authenticate) {
  //       await library?.provider.disconnect();
  //       await authenticate({
  //         provider: "walletconnect",
  //         chainId: info.chainId,
  //         signingMessage: "BBGM Connect",
  //       });
  //     }
  //   }
  // }

  const formattedChainId = chainId;
  try {
    await library.provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: formattedChainId }],
    });
  } catch (error) {
    //console.log(error, typeof error, JSON.stringify(error));
    // 4902 is the error code for attempting to switch to an unrecognized chainId
    if (error) {
      const info = networkConfigs[chainId];

      await library.provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: formattedChainId,
            chainName: info.chainName,
            rpcUrls: [info.rpcUrl],
            nativeCurrency: info.nativeCurrency,
            blockExplorerUrls: [info.blockExplorerUrl],
          },
        ],
      });
      // metamask (only known implementer) automatically switches after a network is added
      // the second call is done here because that behavior is not a part of the spec and cannot be relied upon in the future
      // metamask's behavior when switching to the current network is just to return null (a no-op)
      try {
        await library.provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: formattedChainId }],
        });
      } catch (error) {
        console.debug('Added network but could not switch chains', error);
      }
    } else {
      throw error;
    }
  }
}

import { Popover, Space } from 'antd';
import './characterPopup.scss';
import { CharacterInfo } from './CharacterSlide';
import React from 'react';
import { ModulesData } from './SchoolModuleSlice';
import { ItemInfo } from 'components/Aldoria/Modals/Laboratory';


interface CharacterChoosePopupCraftingProp {
  listAvailableCharacter: CharacterInfo[];
  setListAvailableCharacter: Function;
  setChooseCharacter: any,
  choosedCharacter: any,
  isMining: boolean,
  module: ModulesData,
  craftingItem?: ItemInfo
}

export const CharacterChoosePopupCrafting = ({ module, setChooseCharacter, choosedCharacter, listAvailableCharacter, setListAvailableCharacter, isMining, craftingItem }: CharacterChoosePopupCraftingProp) => {

  const handleCharacterChoose = (char: CharacterInfo) => {
    console.log(craftingItem);
    if (!isMining) {
      if (char.block === 1) {
        if (craftingItem && !checkCharacterStatForNotCrafting(module, char, craftingItem)) {
          if (choosedCharacter) {
            setListAvailableCharacter([...listAvailableCharacter.filter(character => char._id !== character._id), choosedCharacter]);
            setChooseCharacter(char);
          } else {
            setListAvailableCharacter([...listAvailableCharacter.filter(character => char._id !== character._id)]);
            setChooseCharacter(char);
          }
        }
      }
    }
  }

  const checkCharacterStatForNotCrafting = (module: ModulesData, character: CharacterInfo, craftingItem: ItemInfo) => {
    let canCraft = false;
    if (craftingItem.characterRequired != null) {
      Object.keys(craftingItem.characterRequired).forEach(key => {
      console.log(character.stats[key], craftingItem.characterRequired[key]);
        if (character.stats[key] < craftingItem.characterRequired[key]) {
            canCraft = true;
        }
      });
    }
    return canCraft;
  }

  const CharacterImg = ({ character }: { character: CharacterInfo }) => {
    const nftThumbnail = character.metadata?.image.replace('png', 'thumb.jpg');
    let classIsLock = character.block !== 1 ? 'isNotLock' : '';
    let classIsNFT = character.nft_id > 0 ? 'isNFT' : '';
    let classCanCraft;
    
    if (craftingItem) {
        console.log(checkCharacterStatForNotCrafting(module, character, craftingItem));
    checkCharacterStatForNotCrafting(module, character, craftingItem)
      classCanCraft = checkCharacterStatForNotCrafting(module, character, craftingItem) ? 'canNotLearn' : '';
    }

    return (
      <div key={character._id} id={character._id} className={'character-img'}>
        <div className={`${classIsLock} ${classIsNFT} ${classCanCraft}`}>
          {/* <Popover placement="leftTop" content={CharacterInfoContent(character.stats, character.metadata.name, character.block)}> */}
            <img src={nftThumbnail} id={character._id}
              alt="Character" onClick={() => handleCharacterChoose(character)} />
          {/* </Popover> */}
        </div>
      </div>
    )
  }

  const ListAvailableCharacter = () => (
    <>
      {listAvailableCharacter?.map(character => (
        <CharacterImg character={character} />
      ))
      }
    </>
  )

  const CharacterInfoContent = (stats: any, name: string, isLock: number) => (
    <div className={'character-info-container'}>
      <p className={'character-name'}>{name}</p>
      {Object.keys(stats).map(key =>
        stats[key] > 0
          ? <p key={key}>{`${key}: ${stats[key]}`}</p>
          : null
      )}
      {isLock === 0 ? <p className={'red-highlight'}>Please lock character to mining resource</p> : null}

    </div>
  )

  return (
    <div className={'character-choose-modal'}>
      <div className={'body-wrapper'}>
        <ListAvailableCharacter />
      </div>
    </div>
  )
}

export default CharacterChoosePopupCrafting;
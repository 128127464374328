import {ABIStaking} from './ABI/staking';
import {ABIShardWhitelist} from './ABI/whitelist';
import {ABIShardMine} from './ABI/mine';
import {ABIShard} from './ABI/shard';
import {ABINFTPoly} from './ABI/nftpoly';
import {ABIGMDRewards} from './ABI/gmd_rewards';
import {ABIToken} from './ABI/tokenpoly';
import {ABIRouter} from './ABI/router2';
import {ETHLogo, PolygonLogo} from './Logos';

export const addressList = {
  polyTokenAddress: '0xF0047567f883987fc94b84277d2CDfA555f2f917',
  polyNFTAddress: process.env.REACT_APP_ENV === 'PRODUCTION'?'0x0cb1c10E72E034b1FeBb3204643052BcAb868627':'0x1A5EE8A366103AffE5dAE63305d04Fd501178138',
  //stakingAddress: "0x0314040379563B3f17ea834164FDE61760cE78d9",
  stakingAddress: '0xA96B84f0811FD8e2674c45e0eeBa3Ed8Af545B23',
  ethTokenAddress: '0xA3Fdf7bC211cA39816862C7b301253f32aB06589',
  ethNFTAddress: '0xb54819e64a6a9b3a016487f6e6bbd11214076c36',
  sideBridgeAddress: '0xaFb914E3200D3a901414f1049e386DfA33EFB410',
  mainBridgeAddress: '0x912Dc7F6d1fA6235521976ee96cfc7cacc0f4EF0',
  swapTokenAddress: '0xca823529Be92a86dEd865F04d30e6CC21B023b89',
  wETHTokenAddress: '0xA6FA4fB5f76172d178d61B04b0ecd319C5d1C0aa',
  router2Address: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',

  UniAddress: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
  ShardAddress: process.env.REACT_APP_ENV === 'PRODUCTION' ? '0xFE748f506cA280dF8DF078379377DB0339DaAb5E' : '0xFE748f506cA280dF8DF078379377DB0339DaAb5E',
  GMDRewardsAddress: process.env.REACT_APP_ENV === 'PRODUCTION' ? '0xa0B85Fe94A4427439AAD8ba28Df17B8DB0e5b2cD' : '0xa0B85Fe94A4427439AAD8ba28Df17B8DB0e5b2cD',
  ShardWhitelistAddress: process.env.REACT_APP_ENV === 'PRODUCTION' ? '0x1CBFAcAAC8B2750Be7059BFCd69852ABB5D0A5b6' : '0x1CBFAcAAC8B2750Be7059BFCd69852ABB5D0A5b6',
  ShardMineAddress: process.env.REACT_APP_ENV === 'PRODUCTION' ? '0x38a32bFA5cDc178F2d9717E81a0a95F6Ee80ACeD' : '0x38a32bFA5cDc178F2d9717E81a0a95F6Ee80ACeD'

};
export const contractList = [
  {
    name: 'ETH',
    key: '0x1',
    address: addressList.ethTokenAddress,
    decimals: 18,
  },
  {
    name: 'Rinkeby',
    key: '0x4',
    address: addressList.ethTokenAddress,
    decimals: 18,
  },
  {
    name: 'Polygon',
    key: '0x89',
    address: addressList.polyTokenAddress,
    decimals: 18,
  },
  {
    name: 'Amoy',
    key: '0x13882',
    address: addressList.polyTokenAddress,
    decimals: 18,
  },
  {
    name: 'Goerli',
    key: '0x5',
    address: addressList.ethTokenAddress,
    decimals: 18,
  },
  {
    name: 'WETH',
    key: '0x5',
    address: addressList.wETHTokenAddress,
    decimals: 18,
  },
];
export const ActiveNetworks = process.env.REACT_APP_ENV === 'PRODUCTION' ? {polygon: 137, eth: 1} : {polygon: 80002, eth: 4};
export const NFTContractList = process.env.REACT_APP_ENV === 'PRODUCTION' ? {
  '137': addressList.polyNFTAddress.toLowerCase(),
} : {
  '80002': addressList.polyNFTAddress.toLowerCase(),
};
export const EXPLORER_URL = process.env.REACT_APP_ENV === 'PRODUCTION' ? 'https://polygonscan.com/' : 'https://www.oklink.com/amoy/';

export const abiList = {
  [addressList.stakingAddress.toLowerCase()]: ABIStaking,
  [addressList.polyNFTAddress.toLowerCase()]: ABINFTPoly,
  [addressList.polyTokenAddress.toLowerCase()]: ABIToken,
  apiRouter: ABIRouter,
  abiToken: ABIToken,
  abiNFT: ABINFTPoly,
  abiStaking: ABIStaking,
  abiGMDRewards: ABIGMDRewards,
  abiShard: ABIShard,
  abiShardWhitelist: ABIShardWhitelist,
  abiShardMine: ABIShardMine
};

export const firebaseConfig = process.env.REACT_APP_ENV === 'PRODUCTION' ?{}:{
  apiKey: 'AIzaSyAaWpyXAnxVWI7PgT0_Pa3Ze5iXO0UzG_M',
  authDomain: 'omsbeta.firebaseapp.com',
  databaseURL: 'https://omsbeta-default-rtdb.firebaseio.com',
  projectId: 'omsbeta',
  storageBucket: 'omsbeta.appspot.com',
  messagingSenderId: '687685740688',
  appId: '1:687685740688:web:c418a97997b72a417c1015',
  measurementId: 'G-QKXSE42SL1'
};

export const API_ENDPOINT_LOCAL = 'https://localhost/omsbeta/us-central1/';
export const SERVER_API = process.env.REACT_APP_ENV === 'PRODUCTION'?'https://api.onemagicschool.com/':
  process.env.REACT_APP_ENV === 'DEV'?'https://apitest.onemagicschool.com/':'https://localhost/omsbeta/';

export const API_ENDPOINT = SERVER_API;

export const API_TWITTERGETAUTHLINK = SERVER_API + 'TwitterGetAuthLink';
export const API_TWITTERAUTH = SERVER_API + 'TwitterAuth';
export const API_DISCORDAUTH = SERVER_API + 'DiscordAuth';
export const API_REGISTUSER = SERVER_API + 'RegistUser';
export const API_SIGNUPUSER = SERVER_API + 'SignUpUser';
export const API_CheckToken = SERVER_API + 'CheckToken';
export const API_CheckLogin = SERVER_API + 'CheckLogin';
export const API_CheckLoginGoogle = SERVER_API + 'CheckLoginGoogle';
export const API_CheckLoginFacebook = SERVER_API + 'CheckLoginFacebook';
export const API_User_GetInfo = SERVER_API + 'User_GetInfo';

export const API_User_Signup = API_ENDPOINT + 'Oms_Signup';
export const API_User_Login = API_ENDPOINT + 'Oms_Login_v2';
export const API_User_Signup_Google = API_ENDPOINT + 'Oms_SignUpGoogle';
export const API_User_Signup_Metamask = API_ENDPOINT + 'Oms_SignUpMetamask';
export const API_User_VerifyEmail = API_ENDPOINT + 'Oms_VerifyEmail';
export const API_User_Submit_VerifyEmail = API_ENDPOINT + 'Oms_VerifyEmail';
export const API_User_LinkWallet = API_ENDPOINT + 'Oms_LinkWallet';
export const API_User_UnLinkWallet = API_ENDPOINT + 'Oms_UnlinkWallet';
export const API_GET_USER_INFO = SERVER_API + 'Oms_GetUserInfo';

export const API_User_ChangePassword = SERVER_API + 'Oms_ChangePassword';
export const API_User_ChangeUsername = SERVER_API + 'Oms_ChangeUsername';
export const API_User_SendForgotPassword = SERVER_API + 'Oms_SendForgotPassword';
export const API_User_CheckVerifyToken = SERVER_API + 'User_CheckVerifyToken';
export const API_User_UpdateUsername = SERVER_API + 'User_UpdateUsername';
export const API_User_UpdateRefID = SERVER_API + 'User_UpdateRefID';

export const API_User_ClaimShard = API_ENDPOINT + 'Oms_ClaimShard';
export const API_User_ClaimableShard = SERVER_API + 'User_ClaimableShard';
export const API_User_Get_MineShardStatus = API_ENDPOINT + 'Oms_Get_MineShardStatus';
export const API_User_GiveNonNFTs = SERVER_API + 'Oms_GiveNonNFTs';
export const API_User_Connect_Social = API_ENDPOINT + 'Oms_Connect_Social';

export const API_Game_Config = API_ENDPOINT + 'Oms_GameConfig';

export const API_REMOVESOCIAL = SERVER_API + 'RemoveSocial';
export const API_GETUSER = SERVER_API + 'GetUser';
export const API_UPDATEBALANCE = SERVER_API + 'UpdateBalance';
export const API_GETMETA = SERVER_API + 'GetMeta';
export const API_NFT_SALEINFO = SERVER_API + 'Oms_NFT_SaleInfo';
export const API_NFT_MINT = SERVER_API + 'Oms_NFT_mint';
export const API_NFT_GETPACKAGES = SERVER_API + 'Order-Packages';
export const API_ORDER_WHITELISTINFO = SERVER_API + 'Order-WhitelistInfo';
export const API_NFT_BUYPACKAGEWITHCOIN = SERVER_API + 'Purchase-PackageWithCoin';
export const API_ORDER_BUYPACKAGEWITHPAYPAL = SERVER_API + 'Purchase-PackageWithPaypal';
export const API_ORDER_APPLYCOUPON = SERVER_API + 'Order-ApplyCoupon';
export const API_ORDER_CAPTUREPAYPAL = SERVER_API + 'Purchase-CapturePaypal';
export const API_ORDER_UPDATEORDERTX = SERVER_API + 'Order-UpdateOrderTx';
export const API_ORDER_SALEINFO = SERVER_API + 'Order-SaleInfo';
export const API_NFT_GETMETADATA = SERVER_API + 'NFT-GetMetaData';
export const API_NFT_GETOWNER = SERVER_API + 'NFT-GetOwner';
export const API_NFT_GETNFTS = SERVER_API + 'NFT-GetNFTs';

export const API_NFT_UPDATEBLOCK = SERVER_API + 'NFT-updateBlock';
export const API_NFT_UDPATE_STATUS = SERVER_API + 'NFT-UpdateStatus';
// export const API_NFT_UDPATE_STATUS = 'http://127.0.0.1:5001/omsbeta/us-central1/NFT-UpdateStatus';
export const API_NFT_UNLOCK = SERVER_API + 'NFT-Unlock';
export const API_NFT_LOCK = SERVER_API + 'NFT-Lock';
export const API_REWARDS_CLAMABLE = SERVER_API + 'Rewards-ClaimableRewards';
export const API_WHITELIST_GETSHARD = SERVER_API + 'Shard-ShardWhitelist';
export const API_WHITELIST_CLAIMSHARD = SERVER_API + 'Shard-ClaimShard';
export const API_WHITELIST_TRANSACTION = SERVER_API + 'Shard-ClaimShardTransactions';
export const API_WHITELIST_UPDATETRANSACTION = SERVER_API + 'Shard-UpdateClaimShardTransactions';

export const API_MINE_CLAIMSHARD = SERVER_API + 'Shard-ClaimShard';
export const API_MINE_TRANSACTION = SERVER_API + 'Shard-ClaimShardTransactions';
export const API_MINE_UPDATETRANSACTION = SERVER_API + 'Shard-UpdateClaimShardTransactions';

export const API_ALL_SCHOOL_MODULE = SERVER_API + 'Modules-AllModules';
export const API_GAME_USER_INFO = SERVER_API + 'Modules-Game_UserInfo';
export const BUILD_MODULE = SERVER_API + 'Modules-BuildModule';
export const GET_CHARACTER_INFO = SERVER_API + 'Modules-GetAllCharacterInfo';
export const START_MINING = SERVER_API + 'Modules-StartMine';
export const END_MINE = SERVER_API + 'Modules-EndMine';
export const GET_ALL_RESOURCE = SERVER_API + 'Modules-AllResources';
export const API_SEND_OTP_EMAIL = SERVER_API + 'Oms_SendOTPEmail';
export const API_COMPLETE_QUEST = SERVER_API + 'Modules-CompleteQuest';
export const API_GET_AVALABLE_QUEST = SERVER_API + 'Modules-GetAvailableQuest';
export const API_GET_COMPLETED_QUEST = SERVER_API + 'Modules-GetCompleteQuest';
export const API_MINT_TEMP_NFT = SERVER_API + 'GameMD-MintTempNFTs';
export const API_GET_RANDOM_TEMP_NFT = SERVER_API + 'GameMD-GetRandomTempNFTs';

export const API_GET_ALL_ITEMS = SERVER_API + 'Modules-GetAllItems';
export const API_GAME_UPDATE_USER_ITEMS = SERVER_API + 'Modules-UpdateUserItems';

export const API_CRAFTING_ITEM = SERVER_API + 'Modules-CraftingItem';
export const API_CLAIM_CRAFTING_ITEM = SERVER_API + 'Modules-ClaimCraftItem';





export const ChainId = {
  ETHEREUM: 1,
  ROPSTEN: 3,
  RINKEBY: 4,
  GÖRLI: 5,
  KOVAN: 42,
  MATIC: 137,
  MATIC_TESTNET: 80002,
  FANTOM: 250,
  FANTOM_TESTNET: 4002,
  XDAI: 100,
  BSC: 56,
  BSC_TESTNET: 97,
  ARBITRUM: 42161,
  ARBITRUM_TESTNET: 79377087078960,
  MOONBEAM_TESTNET: 1287,
  AVALANCHE: 43114,
  AVALANCHE_TESTNET: 43113,
  HECO: 128,
  HECO_TESTNET: 256,
  HARMONY: 1666600000,
  HARMONY_TESTNET: 1666700000,
  OKEX: 66,
  OKEX_TESTNET: 65,
  CELO: 42220,
  PALM: 11297108109,
  PALM_TESTNET: 11297108099,
  MOONRIVER: 1285,
  FUSE: 122,
  TELOS: 40,
  HARDHAT: 31337,
  MOONBEAM: 1284,
};

export const NETWORK_LIST = {
  [ChainId.ETHEREUM]: {
    chainName: 'Ethereum',
    chainId: '0x1',
    rpcUrls: [
      'https://eth-mainnet.alchemyapi.io/v2/HNQXSfiUcPjfpDBQaWYXjqlhTr1cEY9c',
    ],
    blockExplorerUrls: ['https://etherscan.com'],
    icon: <ETHLogo/>,
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  [ChainId.GÖRLI]: {
    chainName: 'Goerli',
    chainId: '0x5',
    rpcUrls: [
      'https://eth-goerli.alchemyapi.io/v2/Dkk5d02QjttYEoGmhZnJG37rKt8Yl3Im',
    ],
    blockExplorerUrls: ['https://goerli.etherscan.io/'],
    icon: <ETHLogo/>,
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  [ChainId.MATIC]: {
    chainId: '0x89',
    chainName: 'Matic',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://polygon-rpc.com'], // ['https://matic-mainnet.chainstacklabs.com/'],
    blockExplorerUrls: ['https://polygonscan.com'],
    icon: <PolygonLogo/>,
  },
  [ChainId.MATIC_TESTNET]: {
    chainId: '0x13882',
    chainName: 'Amoy',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://rpc-amoy.polygon.technology/'], // ['https://matic-mainnet.chainstacklabs.com/'],
    blockExplorerUrls: ['https://www.oklink.com/amoy'],
    icon: <PolygonLogo/>,
  },
};

import { Button, ConfigProvider, Flex, Modal, Typography } from 'antd'; import { useAppSelector } from '../../app/hooks';
import { loadAllModulesSelector } from '../SchoolModule/SchoolModuleSlice';
import { ModulesData } from '../SchoolModule/SchoolModuleSlice'
import { loadUserGameInfoSelector } from '../SchoolModule/gameUserInfoSlide';
import { useEffect, useState, useRef, useCallback } from 'react';
import MiningPopup from '../SchoolModule/Popup';
import { gameLoadingSelector, setGameLoading } from './AldoriaSlice';
import AldoriaScene from './AldoriaScene';
import { useAppDispatch } from '../../app/hooks';
import Phaser from 'phaser';
import Pubsub from 'pubsub-js';
import classNames from 'classnames';
import { IonPhaser, GameInstance } from '@ion-phaser/react';

import { isMobile } from 'react-device-detect';
import GameQuestModal from 'components/GameQuest/GameQuestModal';
import InventoryModal from './Modals/Inventory';
import Tavern from 'components/SchoolModule/Tavern';
import './Aldoria.scss';

import ReactFlow, {
  ReactFlowProvider,
  Panel,
  useNodesState,
  useEdgesState,
  useReactFlow, Handle, Position, addEdge, ConnectionLineType, Connection, Edge, MarkerType, Viewport, PanOnScrollMode
} from 'reactflow';
import 'reactflow/dist/style.css';

import Dagre from '@dagrejs/dagre';
import dagre from 'dagre';
import { SchoolBuildPopup } from 'components/SchoolModule/SchoolBuildPopup';
import Labotory from './Modals/Laboratory';

const { Title } = Typography;
const gameConfig: GameInstance = {
  width: '100%',
  height: '100%',
  type: Phaser.AUTO,
  scale: {
    // mode: Phaser.Scale.RESIZE,
    autoCenter: Phaser.Scale.CENTER_BOTH,
    mode: Phaser.Scale.RESIZE,
  },

  render: {
    antialias: false,
    pixelArt: true,
    roundPixels: true,
  },
  transparent: true,
  scene: AldoriaScene,
  plugins: {
    scene: [
      { key: 'SpinePlugin', plugin: window.SpinePlugin, mapping: 'spine' }
    ],
  }
};
const PUBSUB_ALDORIA_WINDOW_RESIZE = 'aldoria:window:resize';
const PUBSUB_ALDORIA_OPEN_BUILDER = 'aldoria:open:builder';
const PUBSUB_ALDORIA_NAVIGATE_TO = 'aldoria:navigate:to';
const PUBSUB_ALDORIA_PHASER_IN = 'aldoria:phaser:in';
const PUBSUB_ALDORIA_OPEN_MODULE = 'aldoria:open:module';
const PUBSUB_ALDORIA_BUILT_MODULE = 'aldoria:built:module';
const PUBSUB_ALDORIA_OPEN_QUEST = 'aldoria:open:quest';
function Aldoria() {
  const dispatch = useAppDispatch();
  const [showBuilder, setShowBuilder] = useState<boolean>(false);
  const [showInventory, setShowInventory] = useState<boolean>(false);
  const [showGameQuest, setShowGameQuest] = useState<boolean>(false);
  const allModulesInfo = useAppSelector(loadAllModulesSelector);
  const gameUserInfo = useAppSelector(loadUserGameInfoSelector);
  const gameRef = useRef<HTMLIonPhaserElement>(null);
  const [game, setGame] = useState<GameInstance>();
  const [availableModule, setAvailableModule] = useState<ModulesData[]>([]);
  const [builtModules, setbuiltModule] = useState<ModulesData[]>([]);
  const [canBuildAffterModule, setcanBuildAffterModule] = useState<ModulesData[]>([]);
  const [openMining, setOpenMining] = useState<boolean>(false);
  const [mingingModuleId, setMingingModuleId] = useState<any>('');
  const [isOpenBuilding, setIsOpenBuilding] = useState<boolean>(false);
  const [chooseBuildModuleId, setChooseBuildModuleId] = useState<string>('');
  const [isNewModuleBuild, setIsNewModuleBuild] = useState<boolean>(false);
  const initialize = !useAppSelector(gameLoadingSelector);
  const [, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  const [isOpenTavern, setIsOpenTavern] = useState<boolean>(false);

  const [isOpenLabotory, setIsOpenLabotory] = useState<boolean>(false);
  
  const handleMining = (resourceId: string) => {
    setOpenMining(true);
    setMingingModuleId(resourceId);
  }
  useEffect(() => {
    console.log('setGameLoading');
    dispatch(setGameLoading(true));
  }, [])
  useEffect(() => {
    if (showBuilder || isOpenBuilding) {
      Pubsub.publish(PUBSUB_ALDORIA_PHASER_IN, false)
    }
    else {
      Pubsub.publish(PUBSUB_ALDORIA_PHASER_IN, true)
    }
  }, [showBuilder, isOpenBuilding]);
  useEffect(() => {
    /**
     * setInitialize(true)
     * setGame(Object.assign({}, gameConfig))
     */
    setTimeout(() => dispatch(setGameLoading(false)), 1000);
    window.addEventListener('resize', handleResize)
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);
  const handleResize = async () => {
    //console.log('handleResize');
    setScreenSize({ width: window.innerWidth, height: window.innerHeight })
    Pubsub.publish(PUBSUB_ALDORIA_WINDOW_RESIZE);
  }
  Pubsub.subscribe(PUBSUB_ALDORIA_OPEN_BUILDER, (topic: string, content: any) => {
    console.log('PUBSUB_OPEN_BUILDER:running')
    setShowBuilder(true);
    Pubsub.publish(PUBSUB_ALDORIA_PHASER_IN, false)
  })
  Pubsub.subscribe(PUBSUB_ALDORIA_OPEN_MODULE, (topic: string, content: any) => {
    console.log('PUBSUB_OPEN_MODULE:running');
    console.log(content.id);
    if (content.id === 'TVN') {
      setIsOpenTavern(true)
    } else  if (content.id === 'LAB1'){
      setIsOpenLabotory(true);
    } else {
      handleMining(content.id);
    }
    Pubsub.publish(PUBSUB_ALDORIA_PHASER_IN, false)
  })
  useEffect(() => {
    if (!initialize) {
      return;
    }
    if (!game) {
      setGame(Object.assign({}, gameConfig));
    }
    console.log('setGame');
  }, [initialize]);



  useEffect(() => {
    if (!gameRef) return;
    setTimeout(() => updateGameFunction(), 0);
  }, [gameRef])

  const updateGameFunction = async () => {
    let instance = await gameRef?.current?.getInstance();
    let main: any = instance?.scene.scenes[0];
    if (!(main && instance)) {
      return;
    }
    instance?.scene.wake('MainScene');
    Pubsub.publish(PUBSUB_ALDORIA_PHASER_IN, true);
    // if (location.pathname != '/home' && location.pathname != '/home/') {
    //   toggleScreenSleep(location);
    // }
    /**
     * console.log(instance,instance?.scene.scenes);
     */
  }

  const handleStyle = { left: 10 };

  const flowStyle = {
    width: '70px',
    height: '50px',
    backgroundColor: 'white',
  };

  const buttonStyle = {
    height: '50px',
    border: '1px solid #eee',
    padding: '5px',
    borderRadius: '5px',
    backgroud: 'white'
  }

  type NodeStatus = 'built' | 'available' | 'unavailable' | 'none';
  interface BuiltModule {
    [index: string]: boolean;
  }


  function ButtonNode({ data, isConnectable }: { data: any, isConnectable: boolean }) {
    const handleClick = (nodeStatus: NodeStatus, moduleId: string) => {
      if (nodeStatus === 'available') {
        console.log('handle built' + moduleId);
        setChooseBuildModuleId(moduleId);
        setIsOpenBuilding(true);
      } else if (nodeStatus === 'built') {
        setOpenMining(true);
        setMingingModuleId(moduleId);
      }
    }

    return (
      <div className="school-module-container" key={data.id}>
        <Handle type="target" position={Position.Bottom} />
        <div className='button-container' style={{ display: 'flex', flexDirection: 'column', borderStyle: 'solid', borderRadius: '10px', width: '200px' }}>
          <Button className={`${data.status} nodrag`} onClick={() => handleClick(data.status, data.id)}>{data.label}</Button>
        </div>
        <Handle type="source" position={Position.Top} />
      </div>
    );
  }

  const proOptions = { hideAttribution: true };

  const LayoutFlow = () => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    const nodeWidth = 200;
    const nodeHeight = 100;

    const getLayoutedElements = (nodes: any[], edges: any[], direction = 'TB') => {
      const isHorizontal = direction === 'LR';
      dagreGraph.setGraph({ rankdir: direction, height: 500, width: 1100, ranker: 'network-simplex'});

      nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
      });

      edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
      });

      dagre.layout(dagreGraph);

      nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);

        node.targetPosition = isHorizontal ? 'left' : 'top';
        node.sourcePosition = isHorizontal ? 'right' : 'bottom';

        if (dagreGraph.nodeCount() < 4 && !isMobile) {
          node.position = {
            x: node.position.x + nodeWithPosition.x - nodeWidth / 2,
            y: nodeWithPosition.y - nodeHeight / 2,
          };
        } else {
          node.position = {
            x: 20 + nodeWithPosition.x - nodeWidth / 2,
            y: nodeWithPosition.y - nodeHeight / 2,
          };
        }
        

        return node;
      });

      return { nodes, edges };
    };



    useEffect(() => {
      const moduleTree: ModulesData[] = [];
      //get list of all modules
      let moduleNodes: any[] = [];
      let edgeNodes: any[] = [];
      let nodeStyle = {};
      let status: NodeStatus = 'none';
      let builtModuleIdList: String[] = [];
      let availableModuleList: String[] = [];
      let unavailableModuleList: String[] = [];

      if (allModulesInfo && gameUserInfo) {

        if (gameUserInfo.modules === undefined || Object.keys(gameUserInfo.modules).length === 0) {
          const status = 'available';
          const node = {
            id: 'LUMB1',
            data: {
              label: 'Lumper Camp 1',
              status: status,
              id: 'LUMB1'
            },
            position: { x: 1100/2, y: 0 },
            type: 'button',
            style: nodeStyle
          }
          moduleNodes.push(node);
          availableModuleList.push(node.id);
          console.log(moduleNodes);
        }

        allModulesInfo.forEach(module => {
          status = 'none';
          if (gameUserInfo.modules) {
            builtModuleIdList = Object.keys(gameUserInfo.modules).map(key => key);
          }
          if (gameUserInfo.modules !== undefined && Object.keys(gameUserInfo.modules).length !== 0) {
            if (gameUserInfo.modules[module.id]) {
              status = 'built';
            } else if (module.required_modules.some((reqModule) => (builtModuleIdList.includes(reqModule))) || module.required_modules.length === 0) {
              status = 'available';
              availableModuleList.push(module.id);
            }
          }
          const node = {
            id: module.id,
            data: {
              label: module.name,
              status: status,
              id: module.id
            },
            position: { x: 1100/2, y: 0 },
            type: 'button',
            style: nodeStyle
          }
          if (node.data.status !== 'none') {
            moduleNodes.push(node);
          }
          console.log(moduleNodes);
          module.required_modules.forEach(reqModule => {
            const edge = {
              id: `${module.id}-${reqModule}`,
              source: module.id,
              target: reqModule,
              type: 'smoothstep',
              // animated: true
              markerStart: {
                type: MarkerType.ArrowClosed,
              },
            };
            if (node.data.status !== 'none') {
              edgeNodes.push(edge);
            }
          })
        });

        allModulesInfo.forEach(module => {
          status = 'none';
          if (module.required_modules.some((reqModule) => (availableModuleList.includes(reqModule)))) {
            status = 'unavailable';
            unavailableModuleList.push(module.id);
          } else {
            status = 'none'
          }

          const node = {
            id: module.id,
            data: {
              label: module.name,
              status: status,
              id: module.id
            },
            position: { x: 1100/2, y: 0 },
            style: nodeStyle,
            type: 'button'
          }
          if (node.data.status !== 'none') {
            moduleNodes.push(node);
          }
          module.required_modules.forEach(reqModule => {
            const edge = {
              id: `${module.id}-${reqModule}`,
              source: module.id,
              target: reqModule,
              type: 'smoothstep',
              markerStart: {
                type: MarkerType.ArrowClosed,
              },
            };
            if (node.data.status !== 'none') {
              edgeNodes.push(edge);
            }
          })
        });
        console.log(moduleNodes);
        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
          moduleNodes,
          edgeNodes,
          'BT' // Set default layout to vertical
        );

        setNodes(layoutedNodes);
        setEdges(layoutedEdges);

      }

    }, [allModulesInfo, gameUserInfo, isNewModuleBuild])

    const { fitView } = useReactFlow();
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const nodeType = { button: ButtonNode };

    return (
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        panOnScroll={true}
        proOptions={proOptions}
        nodeTypes={nodeType}
      // panOnDrag={false}
      // panOnScrollMode={PanOnScrollMode.Horizontal}
      >
      </ReactFlow>
    );
  };

  function FlowWithProvider(props: any) {
    return (
      <ReactFlowProvider>
        <LayoutFlow {...props} />
      </ReactFlowProvider>
    );
  }

  const ModulesButton = () => {

    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '600px', width: '1200px' }}>
        <FlowWithProvider />
      </div>
    )
  }
  // const handleBuild = (moduleId: string) => {
  //   console.log('handle built' + moduleId);
  //   setChooseBuildModuleId(moduleId);
  //   setIsOpenBuilding(true);
  // }

  useEffect(() => {
    const buildModulesArr: ModulesData[] = [];
    const avaiModulesArr: ModulesData[] = [];
    const canBuildAffterModuleArr: ModulesData[] = [];
    setTimeout(() => {
      Pubsub.publish(PUBSUB_ALDORIA_PHASER_IN, true)
    }, 2000)

    if (gameUserInfo && gameUserInfo.modules && Object.keys(gameUserInfo.modules).length !== 0) {
      for (let i of Object.keys(gameUserInfo.modules)) {
        setTimeout(() => {
          Pubsub.publish(PUBSUB_ALDORIA_BUILT_MODULE, i);
        }, 2000)
      }
    }

  }, [allModulesInfo, gameUserInfo, isNewModuleBuild])

  return (
    <div
      className={classNames('home-phaser', 'active')}
    >
      {initialize ? (
        <>
          <IonPhaser ref={gameRef} game={game} initialize={initialize}
            style={{ margin: 1 }}
            placeholder={'Loading...'}
            onTouchStart={() => Pubsub.publish(PUBSUB_ALDORIA_PHASER_IN, true)}
            onTouchEnd={() => {
              setTimeout(() => {
                Pubsub.publish(PUBSUB_ALDORIA_PHASER_IN, false)
              }, 0)
            }}
            onMouseEnter={() => !isMobile && Pubsub.publish(PUBSUB_ALDORIA_PHASER_IN, true)}
            onMouseLeave={() => !isMobile && Pubsub.publish(PUBSUB_ALDORIA_PHASER_IN, false)}
            onPointerEnterCapture={() => { }}
            onPointerLeaveCapture={() => { }}
          />
        </>
      ) : (
        <></>
      )}
      {/* floating build button */}
      <Flex gap={10} style={{ position: 'absolute', bottom: '3%', right: '1%' }}>
        <Button type='primary' onClick={() => setShowInventory(true)}>Inventory</Button>
        <Button type='primary' onClick={() => setShowBuilder(true)}>Build</Button>
        <Button type='primary' onClick={() => setShowGameQuest(true)}>Quest</Button>
      </Flex>
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              contentBg: 'rgba(0, 0, 0, 0.8)',
              headerBg: 'rgba(0, 0, 0, 0.8)'
            }
          }
        }}
      >
        <Modal open={showBuilder} footer={null}
          onCancel={() => setShowBuilder(false)} centered={true} width='auto'>
          <ModulesButton />
        </Modal>
      </ConfigProvider>
      <InventoryModal isOpen={showInventory} setIsOpen={setShowInventory} gameUserInfo={gameUserInfo} />
      <GameQuestModal isOpen={showGameQuest} setIsOpen={setShowGameQuest} />
      <Tavern isOpen={isOpenTavern} setIsOpen={setIsOpenTavern} />
      <Labotory isOpen={isOpenLabotory} setIsOpen={setIsOpenLabotory} />
      <MiningPopup isOpen={openMining} setIsOpen={setOpenMining} miningModuleId={mingingModuleId} setMiningModuleId={setMingingModuleId} />
      <SchoolBuildPopup setModuleId={setChooseBuildModuleId} moduleId={chooseBuildModuleId} isOpen={isOpenBuilding} setIsOpen={setIsOpenBuilding} />
    </div>
  );
}

export default Aldoria;

import { CloseOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, Flex, Image, Modal, Space, Spin, Typography, notification } from 'antd'
import Column from 'antd/es/table/Column'
import { on } from 'events'
import { postJSON } from 'helpers/common'
import { API_GET_ALL_ITEMS,API_GAME_UPDATE_USER_ITEMS } from 'helpers/config'
import { get } from 'http'
import { useEffect, useState } from 'react';
import {loadUserGameInfo} from '../../SchoolModule/gameUserInfoSlide';
import { useAppDispatch, useAppSelector } from 'app/hooks';
interface InventoryProps {
  isOpen: boolean,
  setIsOpen: Function,
  gameUserInfo: any
}



const Inventory = ({ isOpen, setIsOpen,gameUserInfo }: InventoryProps) => {
  type NotificationType = 'success' | 'info' | 'warning' | 'error';
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [api, contextHolder] = notification.useNotification();
  const [items, setItems] = useState<any[]>([]);
  const [allItems, setAllItems] = useState<any[]>([]);
  const [mergedItems, setMergedItems] = useState<any[]>([]);
  const maxStack = 99;

  notification.config({
    placement: 'topRight',
    duration: 3,
    rtl: true,
  })

  const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
    api[type]({
      message: message,
      description: description
    });
  };
  

  useEffect(() => {
    if (isOpen) {
      console.log('Inventory is open');
      //clone items
      let _items = JSON.parse(JSON.stringify(gameUserInfo.items));
      for(let i=0;i<_items.length;i++) {
        let item = _items[i];
        item.oIndex = i;
      }
      setItems( _items);
      setIsLoading(false);
      getAllItems();
    }
  }, [isOpen])
  useEffect(() => {
    
  }, [gameUserInfo])
  const getImage = (id:string) => {
    return '/items/' + id + '.png';
  }
  const getAllItems = async () => {
    setIsLoading(true);
    const result = await postJSON(API_GET_ALL_ITEMS);
    setAllItems(result.data);
    setIsLoading(false);
  }
  const onDragStart = (e:any,index:number) => {
   // console.log('drag start');
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('number',index );
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  }
  const ondragover = (e:any) => {
    // console.log('drag over'+index);
    e.preventDefault();
  }
  const onDrop = (e:any,index:number) => {
    //console.log('drop');
    e.preventDefault();
    let data = parseInt(e.dataTransfer.getData('number'));
    // console.log(data);
    // console.log(index);
    // console.log(items[data]);
    if(data != index) {
      let itemFrom = items[data];
      let itemTo = items[index];
      if(itemFrom!=undefined&&itemTo!=undefined) 
        {
          let oItemFrom = allItems.find(item => item.id == itemFrom.id);
          let oItemTo = allItems.find(item => itemTo.id == item.id);
          if(oItemFrom.type == oItemTo.type &&oItemFrom.stack > 1) {
            let total = itemFrom.qty + itemTo.qty;
            console.log(total);
            if(total > oItemTo.stack) {
              let diff = total - oItemFrom.stack;
              itemFrom.qty = diff;
              itemTo.qty = oItemFrom.stack;
              setItems([...items]);
            } else {
              itemTo.qty = total;
              // remove itemFrom
              items.splice(data,1);
              setItems([...items]);
            }
            mergedItems.push([itemFrom.oIndex,itemTo.oIndex]);
            console.log(mergedItems);
          }
          else {
            let temp = items[data];
            items[data] = items[index];
            items[index] = temp;
            setItems([...items]);
          }
        }
     
      else {
        let temp = items[data];
        items[data] = items[index];
        items[index] = temp;
        setItems([...items]);
      }
      
    }
  }
  const getInventoryLayout = (items:any) => {
    let columns = 6;
    let rows = 10;
    let layout = [];
    for (let i = 0; i < rows; i++) {
      // add flex row
      let row = [];
      for (let j = 0; j < columns; j++) {
        // add flex column
        let item = items[i*columns+j];
        let index = i*columns+j;
        if (item) {
          row.push(<Flex key={index} id={'item'+(index)} style={{ width: 50, height: 50, border: '1px solid white',position:'relative' }} draggable onDragOver={(e)=>ondragover(e)} onDragStart={(e)=>onDragStart(e,index)} onDrop={e=>onDrop(e,index)} justify='center' align='center'>
            <Image preview={false} src={getImage(item.id)} width={50} />
            <div style={{position:'absolute',top:0,right:0,fontWeight:'bold'}}>{item.qty}</div>
          </Flex>)
        } else
        row.push(<Flex key={index} id={'item'+(index)} style={{ width: 50, height: 50, border: '1px solid white' }} onDrop={e=>onDrop(e,index)} onDragOver={(e)=>ondragover(e)} justify='center' align='center'>
          <Image preview={false} width={50} />
        </Flex>)
      }

      layout.push(<Flex key={i} gap={10} justify='center' wrap='wrap'>{row}</Flex>);
    }

    return layout;
  }
  const onClose = () => {
    console.log(mergedItems);
    let sortedItems = [];
    for(let i=0;i<items.length;i++) {
      let item = items[i];
      if(item&&item.id)
      sortedItems.push(item.oIndex);
      
    }
    updateUserItems(mergedItems,sortedItems);
  }
  const updateUserItems = async (_mergedItems:any,_sortedItems:any) => {
    setIsLoading(true);
    let data = {
      mergedItems:_mergedItems,
      sortedItems:_sortedItems
    }
    const result = await postJSON(API_GAME_UPDATE_USER_ITEMS,data);
    if(result.data == 'SUCCESS') {
      openNotificationWithIcon('success','Success','Inventory updated successfully');
      setIsOpen(false);
      loadGameUserInfoData();
    }
    else {
      openNotificationWithIcon('error','Error',result.data);
    }
    setIsLoading(false);

  }
  const loadGameUserInfoData = async () => {
    try {
      return await dispatch(loadUserGameInfo());
    } catch (e) {
      openNotificationWithIcon('error', 'Load User info failed', 'Please reload page again');
    }
  };
  
  return (
    <ConfigProvider
      theme={{
        token: {
          colorText: 'white'
        },
        components: {
          Modal: {
            contentBg: 'rgba(0, 0, 0, 0.8)',
            colorText: 'white'
          },
        }
      }}
    >
      <Modal open={isOpen} footer={null}
        onCancel={() => onClose()} centered={true} closeIcon={<CloseOutlined style={{ color: 'white' }} />}>
        {contextHolder}
        {isLoading
          ? <Flex justify='center' align='center'><Spin /></Flex>
          : <Flex wrap='wrap' vertical={true}>
            <Flex justify='center'><Typography.Title level={4}>Inventory</Typography.Title></Flex>
            <Flex gap={10} justify='center' wrap='wrap'>
              {/* populate 5 column and minimum 5 row */}
              {getInventoryLayout(items)}
              {/* {items.map((item, index) => {
                return (
                  <Flex key={index} style={{ width: 100, height: 100, border: '1px solid white' }} justify='center' align='center'>
                    <Image preview={false} src={getImage(item.id)} width={50} />
                  </Flex>
                )
              })} */}

              
              
            </Flex>
            

          </Flex>
        }

      </Modal>
    </ConfigProvider>
  )
}

export default Inventory;
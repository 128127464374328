import Phaser, { BlendModes } from 'phaser';
import GlowFilterPipelinePlugin from 'phaser3-rex-plugins/plugins/glowfilter2pipeline-plugin';
import Pubsub from 'pubsub-js';
import 'phaser/plugins/spine/dist/SpinePlugin';
import { isMobile } from 'react-device-detect';

const ZOOM_SCALE = 1.05;
const PUBSUB_ALDORIA_WINDOW_RESIZE = 'aldoria:window:resize';
const PUBSUB_ALDORIA_OPEN_BUILDER = 'aldoria:open:builder';
const PUBSUB_ALDORIA_NAVIGATE_TO = 'aldoria:navigate:to';
const PUBSUB_ALDORIA_PHASER_IN = 'aldoria:phaser:in';
const PUBSUB_ALDORIA_OPEN_MODULE = 'aldoria:open:module';
const PUBSUB_ALDORIA_BUILT_MODULE = 'aldoria:built:module';

export default class AldoriaScene extends Phaser.Scene {
  public test: any = 'AldoriaScene';
  private smokes: any = { smokes1: [], smokes2: [], smokes3: [] };
  private clouds: any = [];
  private tick: number = 0;
  private towerLights?: TowerLight[];
  private runeCircle?: RuneCircle;
  private phaserIn: boolean = false;
  private imgOverlays: Phaser.GameObjects.Image[] = [];
  private textOverlays: Phaser.GameObjects.Text[] = [];
  private modulesArr: any = [
    {id: 'LUMB1', title: 'Lumber Camp 1', image: 'lumber_camp_1',position: {x: 152, y: 393}},
    {id: 'MINERAL1', title: 'Mineral Mine', image: 'mineral_mine',position: {x: 103, y: 516}},
    {id: 'AT', title: 'Air Temple', image: 'air_temple',position: {x: 554, y: 168}},
    {id: 'RCA', title: 'Rocky Cavern', image: 'rocky_cavern',position: {x: 723, y: 785}},
    {id: 'TVN', title: 'Tavern', image: 'tavern',position: {x: 743, y: 534}},
    {id: 'GH', title: 'Green House', image: 'green_house',position: {x: 568, y: 421}},
    {id: 'AO1', title: 'Academic Office 1', image: 'academic_office_1',position: {x: 989, y: 344}},
    {id: 'LIB1', title: 'Library 1', image: 'library_1',position: {x: 829.5, y: 358}},
    {id: 'LAB1', title: 'Laboratory 1', image: 'laboratory_1',position: {x: 884.5, y: 377 }},
  ];
  private builtModules: any = {};
  constructor() {
    super({
      key: 'AldoriaScene',
    });
  }
  init() {
    //this.cameras.main.setBackgroundColor('#24252A')
    //this.load.image('map', 'main_bg.jpg');
  }

  preload() {
    console.log('preload');
    this.load.image('map', '/aldoria/Base.jpg');
    this.load.image('particle', '/smoke0.png');
    // this.load.image("w0", "/w0.png");
    // this.load.image("w1", "/w1.png");
    // this.load.image("w2", "/w2.png");
    this.load.image('sky', '/aldoria/background_sky.jpg');
    this.load.image('clouds', '/aldoria/clouds.png');
    // this.load.image('foreground_overlay', '/aldoria/foreground_overlay.png');
    // this.load.image('castle', '/aldoria/Castle.png');
    // this.load.image('cottage', '/aldoria/Cottage.png');
    // this.load.image('forge', '/aldoria/Forge.png');
    this.load.image('tavern', '/aldoria/tavern.png');

    this.load.image('shard', '/aldoria/Shard.png');
    //this.load.image('store', '/aldoria/Store.png');
    this.load.image('cloud1', '/aldoria/Cloud1.png');
    this.load.image('cloud2', '/aldoria/Cloud2.png');
    this.load.image('cloud3', '/aldoria/Cloud3.png');
    this.load.image('cloud4', '/aldoria/Cloud4.png');
    this.load.image('cloud5', '/aldoria/Cloud5.png');
    this.load.image('cloud6', '/aldoria/Cloud6.png');
    this.load.image('tower_light', '/aldoria/tower-light.png');
    this.load.image('rune_circle', '/aldoria/rune-circle.png');
    this.load.image('mineral_mine', '/aldoria/mineral-mine.png');
    this.load.image('lumber_camp_1', '/aldoria/lumber_camp_1.png');
    this.load.image('air_temple', '/aldoria/air_temple.png');
    this.load.image('rocky_cavern', '/aldoria/rocky_cavern.png');
    this.load.image('tavern', '/aldoria/tavern.png');
    this.load.image('green_house', '/aldoria/green_house.png');
    this.load.image('academic_office_1', '/aldoria/academic_office_1.png');
    this.load.image('mint_here', '/aldoria/Mint_here_arrow.svg');
    this.load.image('library_1', '/aldoria/library_1.png');
    this.load.image('laboratory_1', '/aldoria/laboratory_1.png');
    this.load.plugin(
      'rexglowfilter2pipelineplugin',
      GlowFilterPipelinePlugin,
      true
    );

    this.load.setPath('/aldoria/Foreground');
    this.load.spine(
      'foreground',
      'Dapp_Foreground.json',
      'Dapp_Foreground.atlas',
      true
    );
  }

  create() {
    let thisScene = this;

    Pubsub.subscribe(PUBSUB_ALDORIA_PHASER_IN, (topic: string, phaserIn: boolean) => {
      console.log('phaserIn', phaserIn);
      thisScene.phaserIn = phaserIn;
    });
    Pubsub.subscribe(PUBSUB_ALDORIA_BUILT_MODULE, (topic: string, id: string) => {
      console.log('built', id);
      if(thisScene.builtModules[id]) return;
      thisScene.addModules(id);
    });
    
    var cam = this.cameras.main;
    
    
    var map = this.add.image(0, 0, 'map').setOrigin(0, 0);
    // add Lumb1 image
    //let lumb1 = this.add.image(15, 338, 'lumber_camp_1').setOrigin(0, 0);
    //thisScene.addModules('LUMB1');
    // var lumber_camp_text = this.add
    //   .text(152, 393, ['Lumber Camp'], {
    //     fontSize: '30px',
    //     color: '#049DE3',
    //     fontFamily: 'Zen Kaku Gothic Antique',
    //     align: 'center',
    //   })
    //   .setShadow(0, 0, '#333', 10, true)
    //   .setStroke('#fff', 2)
    //   .setBlendMode(BlendModes.DARKEN)
    //   .setAlpha(isMobile ? 1 : 0);
    // this.addGlow(
    //   lumber_camp_overlay,
    //   { id: 'LUMB1' },
    //   this,
    //   lumber_camp_text,
    //   0.4
    // );
    
    
    //==============END Particle==============//
    let ww = window.innerWidth;
    let wh = window.innerHeight;
    cam.setBounds(0, 0, 1240, 1000);
    let minZoom = Math.max((1.0 * ww) / 1240, (1.0 * wh) / 1000);
    Pubsub.subscribe(PUBSUB_ALDORIA_WINDOW_RESIZE, () => {
      let newMinZoom = Math.max((1.0 * ww) / 1240, (1.0 * wh) / 1000);
      cam.setZoom((cam.zoom * newMinZoom) / minZoom);
      minZoom = newMinZoom;
      if (cam.zoom < minZoom) {
        cam.setZoom(minZoom);
      }
      if (cam.zoom > minZoom * 2) {
        cam.setZoom(2 * minZoom);
      }
    });

    cam.setZoom(minZoom);
    this.input.on('pointermove', function (p: any, g: any) {
      if (!p.isDown) return;
      //console.log(p,g);
      cam.scrollX -= (p.x - p.prevPosition.x) / cam.zoom;
      cam.scrollY -= (p.y - p.prevPosition.y) / cam.zoom;
    });
    this.input.on(
      'wheel',
      (
        pointer: any,
        gameObjects: any,
        deltaX: any,
        deltaY: any,
        deltaZ: any
      ) => {
        if (deltaY > 0) {
          let newZoom = this.cameras.main.zoom / ZOOM_SCALE;
          if (newZoom > minZoom) {
            this.cameras.main.setZoom(newZoom);
          } else {
            this.cameras.main.setZoom(minZoom);
          }
        }

        if (deltaY < 0) {
          let newZoom = this.cameras.main.zoom * ZOOM_SCALE;
          if (newZoom < 2 * minZoom) {
            this.cameras.main.setZoom(newZoom);
          } else {
            this.cameras.main.setZoom(2 * minZoom);
          }
        }

        // this.camera.centerOn(pointer.worldX, pointer.worldY);
        // this.camera.pan(pointer.worldX, pointer.worldY, 2000, "Power2");
      }
    );
    let _this = this;
  }
  addModules(id:string) {
    let module = this.modulesArr.find((m:any)=>m.id === id);
    if(module){
      let img = this.add.image(module.position.x, module.position.y, module.image);
      let overlay = this.initGlow(this.add.image(module.position.x, module.position.y, module.image));
      console.log('built module', id);
      var text = this.add
      .text(module.position.x, module.position.y, [module.title], {
        fontSize: '30px',
        color: '#049DE3',
        fontFamily: 'Zen Kaku Gothic Antique',
        align: 'center',
      })
      .setShadow(0, 0, '#333', 10, true)
      .setStroke('#fff', 2)
      .setBlendMode(BlendModes.DARKEN)
      .setAlpha(isMobile ? 1 : 0);
    this.addGlow(
      overlay,
      { id: module.id },
      this,
      text,
      0.4
    );
    this.builtModules[id] = true;
    }
  }
  initGlow(img: Phaser.GameObjects.Image) {
    img
      .setBlendMode(Phaser.BlendModes.SCREEN)
      .setInteractive(this.input.makePixelPerfect(0.1))
      .setAlpha(0, 0, 0, 0);
    return img;
  }

  addGlow(
    overlayImg: Phaser.GameObjects.Image,
    href: string | any,
    scene: any,
    textOverlay?: Phaser.GameObjects.Text,
    alpha: number = 1
  ) {
    this.imgOverlays.push(overlayImg);
    if (textOverlay) this.textOverlays.push(textOverlay);
    var postFxPlugin: any = this.plugins.get('rexglowfilter2pipelineplugin');
    var glowConfig = {
      distance: 10,

      outerStrength: 2,
      innerStrength: 0,
      glowColor: 0xdfec7c,
    };
    let thisScene = this;
    overlayImg.on('pointerover', function (p: any, g: any) {
      if (thisScene.phaserIn) {
        overlayImg.setAlpha(alpha, alpha, alpha, alpha);
        textOverlay?.setAlpha(1);
      }
    });

    overlayImg.on('pointerout', function (p: any, g: any) {
      if (thisScene.phaserIn) {
        overlayImg.setAlpha(0, 0, 0, 0);
        textOverlay?.setAlpha(isMobile ? 1 : 0);
      }
    });

    overlayImg.on('pointerup', (p: any, g: any) => {
      let { downX, downY, upX, upY } = p;
      let isDrag = (downX - upX) ** 2 + (downY - upY) ** 2 > 33;
      console.log(isDrag);
      if (!isDrag && thisScene.phaserIn) {
        if (href.id) {
          // console.log('PUBSUB_OPEN_BUILDER');
          // Pubsub.publish(PUBSUB_OPEN_BUILDER, href);
          console.log('PUBSUB_OPEN_MODULE');
          Pubsub.publish(PUBSUB_ALDORIA_OPEN_MODULE, href);
        } else {
          console.log('PUBSUB_NAVIGATE_TO');
          Pubsub.publish(PUBSUB_ALDORIA_NAVIGATE_TO, href);
        }
      }
      if (thisScene.phaserIn) {
        overlayImg.setAlpha(0, 0, 0, 0);
        textOverlay?.setAlpha(isMobile ? 1 : 0);
      }
    });
  }

  update() {
    this.tick++;
    if (this.runeCircle) {
      this.runeCircle.update();
    }
    if (this.towerLights) {
      this.towerLights[0].update();
      this.towerLights[1].update();
    }

    for (let i = 0; i < this.smokes.smokes1.length; i++) {
      const sprite = this.smokes.smokes1[i].s;

      sprite.y -= this.smokes.smokes1[i].r;
      sprite.x += this.smokes.smokes1[i].r * 1.3;
      sprite.setScale(
        sprite.scaleX + this.smokes.smokes1[i].r * 0.01,
        sprite.scaleY + this.smokes.smokes1[i].r * 0.01
      );
      sprite.alpha -= this.smokes.smokes1[i].r * 0.003;
      if (sprite.y < -100) {
        sprite.alpha = 1;
        sprite.setScale(0.3, 0.3);
        sprite.y = 260;
        sprite.x = 900;
      }
    }
    for (let i = 0; i < this.smokes.smokes2.length; i++) {
      const sprite = this.smokes.smokes2[i].s;

      sprite.y -= this.smokes.smokes2[i].r;
      sprite.x += this.smokes.smokes2[i].r * 1.5;
      //console.log((sprite.scaleX));
      sprite.setScale(
        sprite.scaleX + this.smokes.smokes2[i].r * 0.01,
        sprite.scaleY + this.smokes.smokes2[i].r * 0.01
      );
      sprite.alpha -= this.smokes.smokes2[i].r * 0.002;
      if (sprite.y < -100) {
        sprite.alpha = 1;
        sprite.setScale(0.3, 0.3);
        sprite.y = 370;
        sprite.x = 1240;
      }
    }
    for (let i = 0; i < this.smokes.smokes3.length; i++) {
      const sprite = this.smokes.smokes3[i].s;

      sprite.y -= this.smokes.smokes3[i].r;
      sprite.x -= this.smokes.smokes3[i].r * 0.15;
      //console.log((sprite.scaleX));
      sprite.setScale(
        sprite.scaleX + this.smokes.smokes3[i].r * 0.01,
        sprite.scaleY + this.smokes.smokes3[i].r * 0.01
      );
      sprite.alpha -= this.smokes.smokes3[i].r * 0.003;
      if (sprite.y < -100) {
        sprite.alpha = 1;
        sprite.setScale(0.5, 0.5);
        sprite.y = 400;
        sprite.x = 1920;
      }
    }
    for (let i = 0; i < this.clouds.length; i++) {
      const sprite = this.clouds[i].s;
      sprite.x += this.clouds[i].r * 1.3;

      if (sprite.x > 1900) {
        sprite.x = 700;
      }
    }
  }
}

class TowerLight {
  private image: Phaser.GameObjects.Image;
  private maxScale: number = 0.3 + Math.random() * 0.5;
  private currentScale: number = 0;
  private scaleDelta: number = 0.01;
  private angle = Math.random() * 360;
  private angleDelta = Math.random() > 0.5 ? 1 : -1;

  constructor(image: Phaser.GameObjects.Image) {
    this.image = image;
  }

  public update() {
    this.currentScale += this.scaleDelta;
    this.angle += this.angleDelta;

    if (this.currentScale > this.maxScale) {
      this.scaleDelta = -this.scaleDelta;
    }
    if (this.currentScale <= 0) {
      this.maxScale = 0.3 + Math.random() * 0.7;
      this.currentScale = 0;
      this.scaleDelta = -this.scaleDelta;
      this.angle = Math.random() * 360;
      this.angleDelta = Math.random() > 0.5 ? 1 : -1;
    }
    this.image.setScale(this.currentScale).setAngle(this.angle);
  }
}

class RuneCircle {
  private image: Phaser.GameObjects.Image;
  private angle: number = 0;
  private angleDelta: number = 0.5;

  constructor(image: Phaser.GameObjects.Image) {
    this.image = image;
  }

  public update() {
    this.angle += this.angleDelta;
    this.image.setAngle(this.angle);
  }
}

import {ActiveNetworks} from 'helpers/config';
import {URLS} from 'helpers/chains';
import {ethers} from 'ethers';
import CenterContainer from '../Layout/CenterContainer';
import './GameDemo.scss';

function Mint() {
  new ethers.providers.JsonRpcProvider(
    URLS[ActiveNetworks.polygon][0]
  );
  return (
    <CenterContainer isVisible={true} className={'game-demo'} width={1280}>
      <iframe
        scrolling="no"
        id="webgl_iframe"
        frameBorder="0"
        allow="autoplay; fullscreen; vr"
        allowFullScreen=""
        allowvr=""
        mozallowfullscreen="true"
        src="https://play.onemagicschool.com/index.html?v=20230428.0"
        width="1280"
        height="720"
        onmousewheel=""
        webkitallowfullscreen="true"
      ></iframe>
    </CenterContainer>
  );
}

export default Mint;

import { Button, Modal, Radio, RadioChangeEvent, Space, Spin, Typography, notification } from 'antd';
import './popup.scss';
import { postJSON } from 'helpers/common';
import { END_MINE, START_MINING } from 'helpers/config';
import { useEffect, useState } from 'react';
import CharacterChoosePopup from './CharacterChoosePopup';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { loadUserGameInfo, loadUserGameInfoSelector, userGameInfoData } from './gameUserInfoSlide';
import { CharacterInfo, endCharacterMining, loadCharacterInfo, loadCharacterInfoSelector, updateCharacterLearning, updateCharacterMining } from './CharacterSlide';
import { ModulesData, YieldResources, loadAllModulesSelector } from './SchoolModuleSlice';
import Pubsub from 'pubsub-js';
import { ResourceInfo, loadAllResourceSelector } from './ResourceSlide';
import Text from 'components/Layout/Text';
import Tavern from './Tavern';

const PUBSUB_ALDORIA_PHASER_IN = 'aldoria:phaser:in';

interface MiningPopupProp {
  isOpen: boolean,
  setIsOpen: any,
  miningModuleId: string,
  setMiningModuleId: any
}

interface ResourceCostInfo {
  resourceName: string | undefined,
  resource_id: string,
  cost: number,
  isSufficient: boolean
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';
let timeIntevalId: any = null;
let remainingTimeIntevalId: any = null;
export const MiningPopup = ({ isOpen, setIsOpen, miningModuleId, setMiningModuleId }: MiningPopupProp) => {
  const dispatch = useAppDispatch();
  const [isMining, setIsMining] = useState<boolean>(false);
  const [choosedCharacter, setChoosedCharacter] = useState<CharacterInfo>();
  const [listCharacterAvalable, setListCharacterAvalable] = useState<CharacterInfo[]>([]);
  const gameUserInfo = useAppSelector(loadUserGameInfoSelector);
  const userCharacterInfo = useAppSelector(loadCharacterInfoSelector);
  const resourcesInfo = useAppSelector(loadAllResourceSelector);
  const [miningRate, setMiningRate] = useState<number[]>([]);

  const [isLoading, setIsLoading] = useState<any>(false);

  const schoolModule = useAppSelector(loadAllModulesSelector);
  const [miningModule, setMiningModule] = useState<ModulesData>();
  const [amounts, setAmounts] = useState<any[]>([]);
  const [choosedResourceId, setChoosedResourceId] = useState<string>('')
  const [choosedLearningAttribute, setChoosedLearningAttribute] = useState<string>('');
  const [isOpenConfirmedMining, setIsOpenConfirmedMining] = useState<boolean>(false);
  const [isOpenConfirmedEndMining, setIsOpenConfirmedEndMining] = useState<boolean>(false);
  const [isConfirmedMining, setIsConfirmedMining] = useState<boolean>(false);

  const [choosedResourceCost, setChoosedResourceCost] = useState<ResourceCostInfo[] | undefined>(undefined);
  const [isSufficientForMining, setIsSufficientForMining] = useState<boolean>(true);
  const [isMiningModule, setIsMiningModule] = useState<boolean>(false);

  const [timeCountDown, setTimeCountDown] = useState<any[]>([]);

  const [api, contextHolder] = notification.useNotification();
  notification.config({
    placement: 'topRight',
    duration: 3,
    rtl: true,
  })

  const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
    api[type]({
      message: message,
      description: description
    });
  };



  useEffect(() => {
    if (miningModuleId && schoolModule && userCharacterInfo) {
      setChoosedCharacter(undefined);
      const characterInmining = userCharacterInfo?.find(info => info.mining_module === miningModuleId);
      if (characterInmining) {
        setIsMining(true);
        setChoosedCharacter(characterInmining);
      } else {
        setIsMining(false);
        setChoosedCharacter(undefined);
      }
      const characters = userCharacterInfo?.filter(character => (!character.mining_module));
      if (characters) {
        setListCharacterAvalable(characters);
      }

      const _miningModule = schoolModule.find(module => module.id === miningModuleId);
      if (characterInmining && _miningModule) {
        setChoosedResourceId(characterInmining.mining_resource);
      }
      setMiningModule(_miningModule);
      if (_miningModule) {
        setIsMiningModule(_miningModule.yield_attribute.length > 0 || _miningModule.yield_resources.length > 0);
      }
    }
  }, [miningModuleId, schoolModule, userCharacterInfo])

  useEffect(() => {
    if (timeIntevalId) {
      clearInterval(timeIntevalId);
      timeIntevalId = null;
    }
    if (remainingTimeIntevalId) {
      clearInterval(remainingTimeIntevalId);
      remainingTimeIntevalId = null;
    }
    if (isMining && miningModule) {
      if (timeIntevalId) {
        clearInterval(timeIntevalId);
        timeIntevalId = null;
      }
      if (remainingTimeIntevalId) {
        clearInterval(remainingTimeIntevalId);
        remainingTimeIntevalId = null;
      }
      let _timeInteval = setInterval(() => calculateResource(miningModuleId), 1000);
      let _remainingTime = setInterval(() => countDown(miningModuleId), 1000);
      if (_timeInteval)
        timeIntevalId = _timeInteval;
      if (_remainingTime)
        remainingTimeIntevalId = _remainingTime;
    }
  }, [miningModule, isMining])

  const handleClose = () => {
    if (!isLoading) {
      setIsOpen(false);
      setChoosedResourceId('');
      setMiningModule(undefined);
      setMiningModuleId('');
      setAmounts([]);
      setTimeCountDown([]);
      setChoosedResourceCost(undefined);
      setIsConfirmedMining(false);
      setIsSufficientForMining(true);
      if (timeIntevalId) {
        clearInterval(timeIntevalId);
        timeIntevalId = null;
      }
      if (remainingTimeIntevalId) {
        clearInterval(remainingTimeIntevalId);
        remainingTimeIntevalId = null;
      }
      Pubsub.publish(PUBSUB_ALDORIA_PHASER_IN, true)
    }
  }

  const checkIsSufficientResourceBeforeMine = (gameUserInfo: userGameInfoData, miningModule: ModulesData, choosedResourceId: string): boolean => {
    const resourceCost = miningModule.yield_resources?.find(yieldResource => yieldResource.resource_id === choosedResourceId)?.cost;
    let isInsufficient = false;
    if (resourceCost) {
      Object.keys(resourceCost).forEach(key => {
        if (gameUserInfo.resources[key]) {
          if (resourceCost[key] > gameUserInfo.resources[key]) {
            isInsufficient = true;
            return isInsufficient;
          }
        } else if (resourceCost[key] > 0) {
          isInsufficient = true;
          return isInsufficient;
        }
      })
    }
    return !isInsufficient;
  }

  const startMining = async () => {
    const learningAttribute = getLearningResource();
    if (choosedCharacter) {
      let miningData = {
        module_id: miningModuleId,
        charId: choosedCharacter?._id,
        mining_resource: choosedResourceId,
        learning_attribute: learningAttribute
      }
      if (learningAttribute) {
        dispatch(updateCharacterLearning(miningData));
      }
      dispatch(updateCharacterMining(miningData));
      setIsLoading(true);
      await postJSON(START_MINING, miningData).then(async res => {
        if (res.error) {
          throw Error(res.error)
        }
        setIsLoading(false);
        setIsMining(true);
        setChoosedResourceCost(undefined);
        if (choosedResourceCost) {
          await dispatch(loadUserGameInfo());
        }
      }).catch(error => {
        openNotificationWithIcon('error', 'Start mining failed', error.message);
        setIsLoading(false)
      });
    }
  }

  const handleStartMining = async () => {
    if (miningModule) {
      if (choosedResourceCost !== undefined) {
        const checkMiningResourceEnough = checkIsSufficientResourceBeforeMine(gameUserInfo, miningModule, choosedResourceId);
        if (checkMiningResourceEnough) {
          setIsOpenConfirmedMining(true);
        } else {
          openNotificationWithIcon('error', 'Start mining failed', 'Insufficient Resources');
        }
      } else {
        startMining();
      }
    }
  }

  const countDown = (miningModuleId: string) => {
    const charInfoMining = userCharacterInfo?.find(info => info.mining_module === miningModuleId);
    const module = schoolModule.find(module => module.id === miningModuleId);
    if (charInfoMining && module) {
      let mining_time;
      if (charInfoMining.mining_start_time > 0) {
        mining_time = (Date.now() - charInfoMining.mining_start_time) / 1000;
      } else {
        mining_time = (Date.now() - charInfoMining.learning_start_time) / 1000;
      }
      let remainingTime = 0;
      let resources = [];
      let requiredTime = 0;

      if (module.yield_resources.length > 1 || module.yield_attribute.length > 0) {
        let resource: any;
        if (module.yield_resources.length > 0) {
          resource = module.yield_resources.find((resource: any) => resource.resource_id === charInfoMining.mining_resource);
        } else if (module.yield_attribute.length > 0) {
          resource = module.yield_attribute[0];
        }
        if (resource.time !== 'second') {
          if (resource.time === 'minute') {
            requiredTime = 60;
            remainingTime = requiredTime - mining_time;
          }
          if (resource.time === 'hour') {
            requiredTime = 3600;
            remainingTime = requiredTime - mining_time;
          }
          if (resource.time === '24 Hours') {
            requiredTime = 86400;
            remainingTime = requiredTime - mining_time;
          }
        }
        if (remainingTime < 0) {
          remainingTime = 0;
        }
        let days = Math.floor(remainingTime / (60 * 60 * 24));
        let hours = Math.floor((remainingTime % (60 * 60 * 24)) / (60 * 60));
        let minutes = Math.floor((remainingTime % (60 * 60)) / (60));
        let seconds = Math.floor((remainingTime % (60)));

        if (module.yield_resources.length > 1) {
          resources.push({
            name: resource.resource_name,
            remainingTime: `${days}d ${hours}h ${minutes}m ${seconds}s`
          });
        } else if (module.yield_attribute.length > 0) {
          resources.push({
            name: resource.attribute_name,
            remainingTime: `${days}d ${hours}h ${minutes}m ${seconds}s`
          });
        }

        setTimeCountDown(resources)
      }
    }
  }

  const calculateResource = (miningModuleId: string) => {
    const charInfoMining = userCharacterInfo?.find(info => info.mining_module === miningModuleId);
    const module = schoolModule.find(module => module.id === miningModuleId);
    if (charInfoMining && module) {
      let mining_time;
      if (charInfoMining.mining_start_time > 0) {
        mining_time = (Date.now() - charInfoMining.mining_start_time) / 1000;
      } else {
        mining_time = (Date.now() - charInfoMining.learning_start_time) / 1000;
      }
      let resources = [];
      if (module.yield_resources.length > 1 || module.yield_attribute.length > 0) {
        // let resource: any = module.yield_resources.find((resource: any) => resource.resource_id === charInfoMining.mining_resource);
        let resource: any;
        if (module.yield_resources.length > 0) {
          resource = module.yield_resources.find((resource: any) => resource.resource_id === charInfoMining.mining_resource);
        } else if (module.yield_attribute.length > 0) {
          resource = module.yield_attribute[0];
        }
        if (resource.time !== 'second') {
          if (resource.time === 'minute') {
            mining_time = mining_time / 60;
          }
          if (resource.time === 'hour') {
            mining_time = mining_time / 3600;
          }
          if (resource.time === '24 Hours') {
            mining_time = mining_time / 86400;
          }
        }
        let amount = Math.floor(mining_time * resource.value);
        if (amount > resource.max) {
          amount = resource.max;
        }
        if (module.yield_resources.length > 1) {
          resources.push({
            name: resource.resource_name,
            amount: amount,
            max: resource.max
          });
        } else if (module.yield_attribute.length > 0) {
          resources.push({
            name: resource.attribute_name,
            amount: amount,
            max: resource.max
          });
        }

      } else if (module.yield_resources.length > 0) {
        for (let i = 0; i < module.yield_resources.length; i++) {
          let resource = module.yield_resources[i];
          if (resource.time != 'second') {
            if (resource.time == 'minute') {
              mining_time = mining_time / 60;
            }
            if (resource.time == 'hour') {
              mining_time = mining_time / 3600;
            }
            if (resource.time == '24 Hours') {
              mining_time = mining_time / 86400;
            }
          }
          let amount = Math.floor(mining_time * resource.value);
          if (amount > resource.max) {
            amount = resource.max;
          }
          if (amount > 0) {
            resources.push({
              name: resource.resource_name,
              amount: amount,
              max: resource.max
            });
          }
        }
      }

      setAmounts(resources);
    }
  }

  const endMining = async () => {
    const endMiningData = {
      module_id: miningModule?.id,
    }
    setIsLoading(true);
    if (timeIntevalId) {
      clearInterval(timeIntevalId);
      timeIntevalId = null;
    }
    if (remainingTimeIntevalId) {
      clearInterval(remainingTimeIntevalId);
      remainingTimeIntevalId = null;
    }
    await postJSON(END_MINE, endMiningData).then(res => {
      loadGameUserInfoData().then(res => {
        setChoosedCharacter(undefined);
        if (choosedCharacter) {
          dispatch(endCharacterMining(choosedCharacter._id))
        }
        loadCharacterInfoData();
        setIsMining(false);
        setIsLoading(false);
        setChoosedResourceCost(undefined);
        openNotificationWithIcon('success', 'Claim successfully', 'Your Resource or Character have been updated');
      });
    }).catch(error => {
      openNotificationWithIcon('error', 'End mining failed', error.message);
      setIsLoading(false);
    });
  }

  const handleEndMining = async (module_id: string, char_id: string) => {
    if (miningModule) {
      const resourceCost = miningModule.yield_resources?.find(yieldResource => yieldResource.resource_id === choosedResourceId)?.cost;
      if (resourceCost) {
        setIsOpenConfirmedEndMining(true);
      } else {
        endMining()
      }
    }
  }

  const loadGameUserInfoData = async () => {
    try {
      return await dispatch(loadUserGameInfo());
    } catch (e) {
      openNotificationWithIcon('error', 'Load User info failed', 'Please reload page again');
    }
  };

  const loadCharacterInfoData = async () => {
    try {
      return await dispatch(loadCharacterInfo());
    } catch (e) {
      openNotificationWithIcon('error', 'Load chracter info failed', 'Please reload page again');
    }
  };

  const getLearningResource = (): string => {
    if (miningModule && miningModule.yield_attribute.length > 0) {
      if (miningModule.yield_attribute.length < 2) {
        return miningModule.yield_attribute[0].attribute_id
      } else {
        return choosedLearningAttribute;
      }
    } else {
      return '';
    }
  }

  const getMiningResource = (): string => {
    if (miningModule && miningModule.yield_resources.length > 0) {
      if (miningModule.yield_resources.length < 2) {
        return miningModule.yield_resources[0].resource_id
      } else {
        return choosedResourceId;
      }
    } else {
      return '';
    }
  }


  const ActionLabel = () => {
    if (isMining) {
      return <Button type={'primary'} size={'large'} loading={isLoading} onClick={() => {
        if (choosedCharacter) {
          handleEndMining(miningModuleId, choosedCharacter._id);
        }
      }}>Claim</Button>
    }

    const learningAttribute = getLearningResource();
    const miningResource = getMiningResource();

    if (isSufficientForMining) {
      if (miningModule && miningModule.yield_resources.length > 1) {
        if (choosedResourceId) {
          return <Button type={'primary'} size={'large'} loading={isLoading} onClick={() => {
            if (choosedCharacter) {
              handleStartMining();
            }
          }}>{miningModule.yield_button}</Button>
        }
      } else if (miningModule && miningModule.yield_resources.length === 1) {
        setChoosedResourceId(miningModule.yield_resources[0].resource_id)
        if (choosedCharacter) {
          return <Button type={'primary'} size={'large'} loading={isLoading} onClick={() => {
            if (choosedCharacter) {
              handleStartMining();
            }
          }}>{miningModule.yield_button}</Button>
        }
      } else if (miningModule && miningModule.yield_attribute.length === 1) {
        if (choosedCharacter) {
          return <Button type={'primary'} size={'large'} loading={isLoading} onClick={() => {
            if (choosedCharacter) {
              handleStartMining();
            }
          }}>{miningModule.yield_button}</Button>
        }
      }
    }
    return <Button type={'primary'} size={'large'} onClick={() => handleClose()}>Close</Button>
  }

  const handleUnmountCharacterFromModule = () => {
    if (!isMining) {
      if (choosedCharacter) {
        setListCharacterAvalable([...listCharacterAvalable, choosedCharacter])
        setChoosedCharacter(undefined);
      }
    }
  }

  const handleChooseResource = (e: RadioChangeEvent) => {
    setChoosedResourceId(e.target.value);
    setChoosedResourceCost(undefined);
    setIsSufficientForMining(true);
    console.log(e.target.value);
    const resource = miningModule?.yield_resources.find(resource => resource.resource_id === e.target.value);
    if (resource?.cost) {
      const resourceCost = Object.keys(resource.cost).map(key => {
        const _resourceInfo = resourcesInfo.find(resource => resource.id === key);
        let _isSufficient = false;
        if (gameUserInfo.resources[key] && resource.cost[key] <= gameUserInfo.resources[key]) {
          _isSufficient = true;
        } else {
          setIsSufficientForMining(false);
        }
        return {
          resourceName: _resourceInfo?.name,
          resource_id: key,
          cost: resource.cost[key],
          isSufficient: _isSufficient
        }
      })
      setChoosedResourceCost(resourceCost ? resourceCost : undefined);
    }
  }

  const handleCloseConfirmedMining = () => {
    setIsOpenConfirmedMining(false);
  }

  const handleConfirmedMining = async () => {
    startMining();
    setIsOpenConfirmedMining(false);
  }

  const handleConfirmedEndMining = () => {
    endMining();
    setIsOpenConfirmedEndMining(false);
  }
  const handleCloseConfirmedEndMining = () => {
    setIsOpenConfirmedEndMining(false);
  }




  return (

    <div className='container'>
      {contextHolder}
      <Modal open={isOpen} footer={null} onCancel={() => handleClose()}
        centered={true} className={'mining-modal'} destroyOnClose>
        <div className={'header-wrapper'} >
          <p>{miningModule?.name}</p>
        </div>
        <div className={'body-wrapper'}>
          {isMiningModule
            ? <div className='character-image-container'>
              {choosedCharacter || isMining
                // eslint-disable-next-line jsx-a11y/alt-text
                ? <img src={choosedCharacter?.metadata.image} onClick={() => handleUnmountCharacterFromModule()} />
                : <div className='place-holder-char-img'></div>}
            </div>
            : null
          }
          {isMiningModule
            ? <div className='resource-counting'>
              {(isMining && amounts.length > 0)
                ? amounts.map((amount: any) => {
                  return <Typography>{`${amount.name}: ${amount.amount}/${amount.max}`}</Typography>
                })
                : (isMining && amounts.length === 0 && !isLoading)
                  ? <Spin />
                  : <p>Put characters here</p>}
            </div>
            : null
          }

          {miningModule?.id === 'TVN'
            ? <Typography>Comming soon</Typography>
            : null
          }

          <div className='remaining-time'>
            {(isMining && timeCountDown.length > 0)
              ? timeCountDown.map((amount: any) => {
                return <Typography>{`${amount.remainingTime}`}</Typography>
              })
              : (isMining && amounts.length === 0 && !isLoading)
                ? <Spin />
                : null
            }
          </div>
          {choosedResourceCost
            ? choosedResourceCost.map(cost => (
              <div className='cost' key={cost.resource_id} style={{ color: cost.isSufficient ? 'white' : 'red' }}>
                {`${cost.resourceName}: ${cost.cost}`}
              </div>
            ))
            : null
          }
          {/* */}
          {choosedCharacter && (miningModuleId === 'LIB1' || miningModuleId === 'GH') && !isMining ?
            <Space style={{ margin: '15px' }}>
              <Radio.Group onChange={handleChooseResource} defaultValue="" optionType='button' className='resource-button-group'>
                {miningModule?.yield_resources?.map((resource: any) => {
                  let checkStat = false;
                  resource.required_stats?.forEach((stat: any) => {
                    choosedCharacter.stats[stat.id] >= stat.min ? checkStat = true : checkStat = false;
                  }
                  )
                  return (checkStat
                    ? <Radio.Button value={resource.resource_id}>{resource.resource_name}</Radio.Button>
                    : <Radio.Button disabled value={resource.resource_id} style={{ color: 'white' }}>{resource.resource_name}</Radio.Button>)
                }
                )
                }
              </Radio.Group>
            </Space> : null}

          <ActionLabel />
          {miningModule && miningModule.yield_resources.length > 0 && miningModuleId !== 'LIB1' && miningModuleId !== 'GH' && <p>Rate: {miningModule.yield_resources.map(
            (yield_resource: { value: any; resource_name: any; time: any }) => {
              return <>{`${yield_resource.value} (${yield_resource.resource_name}/${yield_resource.time})`}</>
            }
          )}</p>}
          {miningModule && miningModule.yield_resources.length > 0 && (miningModuleId === 'LIB1' || miningModuleId === 'GH') && <p>Rate: {miningModule.yield_resources.map(
            (yield_resource: { value: any; resource_name: any; time: any; resource_id: any }) => {
              if (yield_resource.resource_id === choosedResourceId) {
                return <>{`${yield_resource.value} (${yield_resource.resource_name}/${yield_resource.time})`}</>
              }
            }
          )}</p>}
          {miningModule && miningModule.yield_attribute.length > 0 && <p>Rate: {miningModule.yield_attribute.map(
            (yield_attribute: { value: any; attribute_name: any; time: any; attribute_id: any }) => {
              return <>{`${yield_attribute.value} (${yield_attribute.attribute_name}/${yield_attribute.time})`}</>
            }
          )}</p>}

          {miningModule && isMiningModule
            ? <div style={{position: 'fixed', right: '4%'}}>
              <CharacterChoosePopup module={miningModule} isMining={isMining} setChooseCharacter={setChoosedCharacter} choosedCharacter={choosedCharacter}
                listAvailableCharacter={listCharacterAvalable} setListAvailableCharacter={setListCharacterAvalable} />
            </div>
            : null}


        </div>
        <div className={'footer-wrapper'}>
        </div>
      </Modal>
      <Modal className='confirmed-modal' open={isOpenConfirmedMining} footer={null} destroyOnClose onCancel={() => handleCloseConfirmedMining()} centered={true}>
        <div>
          <Text>Do you want to spend this amount?</Text>
          <div className='resource-container'>
            {choosedResourceCost?.map(resource => (
              <Text fontSize='sm' key={resource.resource_id}>{`${resource.resourceName}: ${resource.cost}`}</Text>
            ))}
          </div>
          <div className='confirmed-button-container'>
            <Button onClick={() => handleConfirmedMining()}>Yes</Button>
            <Button type='primary' onClick={() => handleCloseConfirmedMining()}>No</Button>
          </div>
        </div>
      </Modal>
      <Modal className='confirmed-modal' open={isOpenConfirmedEndMining} footer={null} destroyOnClose onCancel={() => handleCloseConfirmedEndMining()} centered={true}>
        <div>
          <Text>You will not retrieve the Costed resources. Are you sure you want to stop?</Text>
          <div className='confirmed-button-container'>
            <Button onClick={() => handleConfirmedEndMining()}>Yes</Button>
            <Button type='primary' onClick={() => handleCloseConfirmedEndMining()}>No</Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default MiningPopup;
